import React from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import DecoBarVertical from "../DecoBarVertical";
import useRedirect from "../../hooks/useRedirect";
import { Header, Tab, Tabs, Wrapper, WrapperInner } from "./DropDown";

const DropDownProducts = ({ close, refSource }) => {
  const wrapperRef = React.useRef(null);
  const redirectTo = useRedirect();
  useOutsideClick(wrapperRef, refSource, close);

  return (
    <Wrapper ref={wrapperRef}>
      <WrapperInner>
        <Header>
          <span>Product</span>
          <span>Try our products for free</span>
        </Header>
        <Tabs>
          <Tab color={"primary"} onClick={redirectTo("/products/bankloop")}>
            <span>
              <strong>bank</strong>loop
            </span>
            <span>Verified bank data</span>
          </Tab>
          <DecoBarVertical />
          <Tab color={"third"} onClick={redirectTo("/products/bankloopplus")}>
            <span>
              <strong>bank</strong>loop+
            </span>
            <span>Enhanced bank data</span>
          </Tab>
        </Tabs>
      </WrapperInner>
    </Wrapper>
  );
};

export default DropDownProducts;

import React from "react";
import styled from "styled-components";
import * as mixins from "config/theme/mixins";
import useRedirect from "../../hooks/useRedirect";

const Wrapper = styled.div`
  ${mixins.svg};

  cursor: pointer;
`;

const LogoWide = ({ width }) => {
  const setRedirect = useRedirect();

  return (
    <Wrapper onClick={setRedirect("/")}>
      <svg
        width={width || "196"}
        viewBox="0 0 196 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M56.2875 30.5434C55.4871 29.544 55.0874 28.2824 55.0874 26.7567V16.0713H52.7254V11.872H55.1999L56.0625 6.323H60.2242V11.872H63.6359V16.0713H60.2242V26.2321C60.2242 27.3069 60.7612 27.8439 61.8361 27.8439H63.6359V31.4435C63.2106 31.5931 62.6797 31.73 62.0426 31.8561C61.405 31.9805 60.8115 32.0436 60.2617 32.0436C58.4116 32.044 57.087 31.5441 56.2875 30.5434Z"
          fill="#3C3C3B"
        />
        <path
          d="M66.2789 11.8724H70.6657L71.0778 15.0591H71.3404C71.7653 13.9343 72.3151 13.0479 72.9902 12.3975C73.6648 11.7476 74.5517 11.4228 75.6521 11.4228C76.1265 11.4228 76.5457 11.4665 76.9079 11.5538C77.2698 11.6416 77.5014 11.71 77.602 11.7599V16.5592H76.1768C74.5023 16.5592 73.2902 17.0155 72.5401 17.9275C71.7904 18.8404 71.4154 20.2211 71.4154 22.0707V31.594H66.2789V11.8724Z"
          fill="#3C3C3B"
        />
        <path
          d="M81.3248 30.3938C80.1626 29.2942 79.5815 27.5327 79.5815 25.1073V11.8728H84.7179V24.0205C84.7179 25.2454 85.0047 26.1327 85.5801 26.6825C86.1546 27.2323 87.0048 27.5072 88.1296 27.5072C89.279 27.5072 90.2352 27.1016 90.998 26.2889C91.7601 25.4766 92.1413 24.4702 92.1413 23.2708V11.8732H97.2773V31.5943H92.8906L92.5159 29.0078H92.2534C90.6535 31.0322 88.6039 32.0444 86.1047 32.0444C84.0803 32.0436 82.487 31.4938 81.3248 30.3938Z"
          fill="#3C3C3B"
        />
        <path
          d="M102.433 30.3374C100.871 29.2003 100.089 27.6824 100.089 25.782L100.127 25.332H105.114V25.5945C105.138 26.4699 105.532 27.1005 106.294 27.4883C107.057 27.8757 107.988 28.069 109.087 28.069C110.112 28.069 110.987 27.9321 111.712 27.6564C112.437 27.3819 112.8 26.9319 112.8 26.3075C112.8 25.6077 112.43 25.0826 111.693 24.7323C110.956 24.3832 109.8 24.0333 108.225 23.683C106.6 23.308 105.276 22.9276 104.251 22.5393C103.226 22.1528 102.338 21.5457 101.589 20.721C100.839 19.8967 100.464 18.7967 100.464 17.4218C100.464 15.4972 101.27 14.0159 102.882 12.9786C104.494 11.9417 106.575 11.4228 109.124 11.4228C111.499 11.4228 113.46 11.9042 115.011 12.8661C116.56 13.8288 117.335 15.1345 117.335 16.7843L117.298 17.6465H112.311V17.5715C112.286 16.8469 111.974 16.3033 111.374 15.9406C110.774 15.5784 109.961 15.397 108.937 15.397C107.912 15.397 107.1 15.5347 106.5 15.8092C105.9 16.0849 105.6 16.4719 105.6 16.9718C105.6 17.5966 105.943 18.059 106.631 18.359C107.318 18.6591 108.424 18.9587 109.949 19.2587C111.623 19.6334 112.986 20.0088 114.036 20.3835C115.086 20.7589 115.998 21.3709 116.773 22.2208C117.548 23.0706 117.935 24.2209 117.935 25.6695C117.935 29.9195 114.923 32.044 108.899 32.044C106.151 32.044 103.995 31.4757 102.433 30.3374Z"
          fill="#3C3C3B"
        />
        <path
          d="M122.469 30.5434C121.669 29.544 121.269 28.2824 121.269 26.7567V16.0713H118.907V11.872H121.382L122.245 6.323H126.406V11.872H129.818V16.0713H126.406V26.2321C126.406 27.3069 126.943 27.8439 128.019 27.8439H129.818V31.4435C129.393 31.5931 128.862 31.73 128.225 31.8561C127.587 31.9805 126.993 32.0436 126.444 32.0436C124.593 32.044 123.269 31.5441 122.469 30.5434Z"
          fill="#3C3C3B"
        />
        <path
          d="M135.73 4.48572H132.43V31.5932H135.73V4.48572Z"
          fill="#B8B4B3"
        />
        <path
          d="M140.63 29.5127C139.105 27.8258 138.343 25.2326 138.343 21.7332C138.343 18.2337 139.105 15.6406 140.63 13.9533C142.154 12.266 144.429 11.4224 147.454 11.4224C150.478 11.4224 152.753 12.266 154.278 13.9533C155.802 15.6406 156.565 18.2337 156.565 21.7332C156.565 25.2326 155.802 27.8258 154.278 29.5127C152.753 31.2 150.478 32.0436 147.454 32.0436C144.429 32.0436 142.154 31.2 140.63 29.5127ZM151.803 27.5629C152.703 26.4011 153.153 24.5073 153.153 21.8828V21.5828C153.153 18.9583 152.702 17.0649 151.803 15.9023C150.903 14.7401 149.453 14.159 147.454 14.159C145.453 14.159 144.005 14.7401 143.105 15.9023C142.204 17.0649 141.755 18.9583 141.755 21.5828V21.8828C141.755 24.5073 142.205 26.4011 143.105 27.5629C144.005 28.7251 145.453 29.3058 147.454 29.3058C149.453 29.3062 150.903 28.7255 151.803 27.5629Z"
          fill="#B8B4B3"
        />
        <path
          d="M160.467 29.5127C158.942 27.8258 158.18 25.2326 158.18 21.7332C158.18 18.2337 158.942 15.6406 160.467 13.9533C161.991 12.266 164.266 11.4224 167.291 11.4224C170.315 11.4224 172.59 12.266 174.115 13.9533C175.639 15.6406 176.402 18.2337 176.402 21.7332C176.402 25.2326 175.639 27.8258 174.115 29.5127C172.59 31.2 170.315 32.0436 167.291 32.0436C164.266 32.0436 161.992 31.2 160.467 29.5127ZM171.64 27.5629C172.54 26.4011 172.99 24.5073 172.99 21.8828V21.5828C172.99 18.9583 172.54 17.0649 171.64 15.9023C170.74 14.7401 169.29 14.159 167.291 14.159C165.291 14.159 163.842 14.7401 162.942 15.9023C162.042 17.0649 161.592 18.9583 161.592 21.5828V21.8828C161.592 24.5073 162.042 26.4011 162.942 27.5629C163.842 28.7251 165.29 29.3058 167.291 29.3058C169.29 29.3062 170.74 28.7255 171.64 27.5629Z"
          fill="#B8B4B3"
        />
        <path
          d="M178.978 11.8724H181.452L181.865 14.5344H182.09C182.739 13.5098 183.595 12.735 184.658 12.21C185.72 11.6849 186.901 11.4224 188.201 11.4224C190.7 11.4224 192.625 12.2602 193.975 13.9343C195.325 15.6097 196 18.2086 196 21.7332C196 25.1824 195.331 27.7632 193.994 29.4756C192.656 31.1876 190.826 32.0432 188.502 32.0432C187.102 32.0432 185.915 31.8305 184.939 31.4056C183.964 30.9811 183.152 30.2937 182.503 29.3433H182.277V38.0797H178.978V11.8724ZM190.413 28.5936C191.163 28.1196 191.712 27.3448 192.063 26.2692C192.413 25.1947 192.588 23.7325 192.588 21.8824V21.7328C192.588 19.858 192.412 18.3714 192.063 17.271C191.712 16.1714 191.157 15.3776 190.395 14.8901C189.631 14.4025 188.626 14.159 187.376 14.159C185.627 14.159 184.339 14.7965 183.515 16.0713C182.69 17.346 182.277 19.2336 182.277 21.7328V21.8824C182.277 24.3321 182.683 26.1818 183.496 27.4318C184.308 28.6814 185.601 29.3062 187.376 29.3062C188.651 29.3066 189.663 29.0692 190.413 28.5936Z"
          fill="#B8B4B3"
        />
        <path
          d="M34.1422 12.273C31.2569 12.273 28.9093 9.92547 28.9093 7.0401C28.9093 5.42247 29.6479 3.97423 30.8048 3.01354C27.8724 1.19437 24.4422 0.10427 20.7697 0V7.83223C27.1285 8.12773 32.2481 13.2473 32.544 19.6065H40.3754C40.2831 16.3577 39.4163 13.3009 37.959 10.6084C37.0033 11.6297 35.6482 12.273 34.1422 12.273Z"
          fill="#FAB833"
        />
        <path
          d="M19.6066 7.83141V0C15.9336 0.103858 12.5034 1.19396 9.57104 3.01354C10.7279 3.97423 11.4665 5.42206 11.4665 7.0401C11.4665 9.92547 9.11892 12.2726 6.23356 12.2726C4.72761 12.2726 3.3721 11.6297 2.41677 10.608C0.959452 13.3009 0.0923185 16.3573 0 19.6061H7.83183C8.12733 13.2469 13.2473 8.12691 19.6066 7.83141Z"
          fill="#5BBDD7"
        />
        <path
          d="M7.83178 20.7692H0.000366211C0.0926847 24.0184 0.959404 27.0753 2.41672 29.7669C3.37246 28.7456 4.72757 28.1023 6.23351 28.1023C9.11888 28.1023 11.4664 30.4498 11.4664 33.3352C11.4664 34.9528 10.7279 36.4011 9.5714 37.3622C12.5033 39.1813 15.9336 40.271 19.6061 40.3753V32.5435C13.2472 32.2484 8.12728 27.1284 7.83178 20.7692Z"
          fill="#ED6C77"
        />
        <path
          d="M28.9093 33.3352C28.9093 30.4498 31.2568 28.1027 34.1422 28.1027C35.6481 28.1027 37.0032 28.7457 37.959 29.7673C39.4163 27.0749 40.283 24.018 40.3753 20.7696H32.5435C32.248 27.1284 27.128 32.2484 20.7692 32.5435V40.3749C24.4417 40.2706 27.8719 39.1814 30.8043 37.3618C29.6478 36.4011 28.9093 34.9528 28.9093 33.3352Z"
          fill="#B8B4B3"
        />
        <path
          d="M30.0719 7.04005C30.0719 9.28413 31.8977 11.1099 34.1417 11.1099C36.3862 11.1099 38.212 9.28413 38.212 7.04005C38.212 4.79597 36.3862 2.97021 34.1417 2.97021C31.8977 2.97021 30.0719 4.79597 30.0719 7.04005Z"
          fill="#FAB833"
        />
        <path
          d="M34.1422 29.2654C31.8982 29.2654 30.0724 31.0916 30.0724 33.3352C30.0724 35.5793 31.8982 37.4051 34.1422 37.4051C36.3863 37.4051 38.2121 35.5793 38.2121 33.3352C38.2121 31.0916 36.3863 29.2654 34.1422 29.2654Z"
          fill="#B8B4B3"
        />
        <path
          d="M6.23354 11.1103C8.47762 11.1103 10.3034 9.28455 10.3034 7.04047C10.3034 4.79597 8.47762 2.97021 6.23354 2.97021C3.98946 2.97021 2.1637 4.79597 2.1637 7.04047C2.1637 9.28414 3.98946 11.1103 6.23354 11.1103Z"
          fill="#5BBDD7"
        />
        <path
          d="M6.23354 37.4051C8.47762 37.4051 10.3034 35.5793 10.3034 33.3352C10.3034 31.0916 8.47762 29.2654 6.23354 29.2654C3.98946 29.2654 2.1637 31.0916 2.1637 33.3352C2.1637 35.5797 3.98946 37.4051 6.23354 37.4051Z"
          fill="#ED6C77"
        />
      </svg>
    </Wrapper>
  );
};

export default LogoWide;

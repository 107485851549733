import styled from "styled-components";

export const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 1rem;

  @media screen and (max-width: 992px) {
    font-size: 20px;
    margin-bottom: 25px;
  }
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.colors.bgAlt};
  border-radius: 70px;
  @media screen and (max-width: 1281px) {
    border-radius: 0;
  }
  padding: 100px 7rem;
  width: 100%;
  max-width: 1440px;

  @media screen and (max-width: 1281px) {
    padding: 70px;
  }

  @media screen and (max-width: 550px) {
    padding: 40px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-flow: row;
  grid-gap: 3rem;
  width: 100%;

  justify-content: center;
  justify-items: center;
  height: max-content;
  align-items: center;

  @media screen and (max-width: 992px) {
    grid-gap: 0;
  }
`;

export default {
  Wrapper,
  Content,
  Header,
};

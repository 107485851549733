import React from "react";
import styled, { css } from "styled-components";
import ArrowDownIcon from "../svg/ArrowDown";
import { linkHorizontalBar } from "../../config/theme/mixins";

const getColor = ({ color, active, theme }) => {
  if (active) {
    return theme.colors.primary;
  }

  if (color) {
    return theme.colors[color];
  }

  return theme.colors.fg;
};

const Wrapper = styled.a`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  align-items: flex-start;
  align-content: center;
  position: relative;
  line-height: 100%;
  
  &,
  &:visited,
  &:focus {
    color: ${getColor};
    text-decoration: none;
    cursor: pointer;
    transition: all 300ms;
    /*font-weight: ${({ weight, theme }) =>
      weight ? theme.fonts.weight[weight] : theme.fonts.weight.bold};*/
  }

  svg path {
    stroke: ${getColor};
  }

  ${({ active, noHorBar, colorOnHover }) =>
    !active && !noHorBar && !colorOnHover
      ? linkHorizontalBar(getColor)
      : css`
          &:hover {
            color: ${({ theme }) => theme.colors.primary};
          }
        `}

  /*
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    svg path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
  */
`;

const LinkArrowDown = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Wrapper ref={ref} {...props}>
      {children}
      <ArrowDownIcon />
    </Wrapper>
  );
});

export default LinkArrowDown;

import styled from "styled-components";

const H4 = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 120%;
`;

export default H4;

import React, { useState } from "react";
import history from "./utils/history";
import { Route as ReactRoute, Router } from "react-router-dom";
import Route from "./utils/AnimatedRoute";
import FrontPage from "containers/Front";
import OurStoryPage from "containers/OurStory";
import ContactUsPage from "containers/ContactUs";
import PricingPage from "containers/Pricing";
import { ThemeProvider } from "styled-components";
import theme from "config/theme/theme";
import ProductsIdloopPage from "./containers/Products/Idloop";
import ProductsBankloopPage from "./containers/Products/Bankloop";
import ProductsBankloopPlusPage from "./containers/Products/BankloopPlus";
import ToastContainer from "./components/ToastContainer";
import TermsOfUse from "./containers/TermsOfUse";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Faq from './containers/Faq';
import "./assets/css/animation.css";
import PageNotFound from "./containers/PageNotFound";
import "config/theme/App.scss";
import {
  AnimatedRoutesContainer,
  AppStyled,
  ContextIsRouteMatched,
} from "./App.styles";

/*
 * App
 */
function App() {
  const [isRouteMatched, setIsRouteMatched] = useState(false);

  const contextValue = React.useMemo(
    () => ({
      isRouteMatched,
      setIsRouteMatched,
    }),
    [isRouteMatched, setIsRouteMatched]
  );

  return (
    <ContextIsRouteMatched.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        <AppStyled className="App">
          <Router history={history}>
            <AnimatedRoutesContainer>
              <Route path="/" exact>
                <FrontPage />
              </Route>
              <Route path="/our_story" exact>
                <OurStoryPage />
              </Route>
              <Route path="/contact_us" exact>
                <ContactUsPage />
              </Route>
              <Route path="/pricing" exact>
                <PricingPage />
              </Route>
              <Route path="/products" exact>
                <ProductsIdloopPage />
              </Route>
              <Route path="/products/idloop" exact>
                <ProductsIdloopPage />
              </Route>
              <Route path="/products/bankloop" exact>
                <ProductsBankloopPage />
              </Route>
              <Route path="/products/bankloopplus" exact>
                <ProductsBankloopPlusPage />
              </Route>
              <Route path="/terms-of-use" exact>
                <TermsOfUse />
              </Route>
              <Route path="/privacy-policy" exact>
                <PrivacyPolicy />
              </Route>
              <Route path="/faq" exact>
                <Faq />
              </Route>
              <ReactRoute path="/(.*)">
                <PageNotFound />
              </ReactRoute>
            </AnimatedRoutesContainer>
          </Router>
          {/*<Fade in={modalIsOpen}>
          <Modal open={true} onClose={() => setModalIsOpen(false)}>
            Test
          </Modal>
        </Fade>*/}
          <ToastContainer />
        </AppStyled>
      </ThemeProvider>
    </ContextIsRouteMatched.Provider>
  );
}

export default App;

import React from 'react';
import styled from 'styled-components';
import { getColor } from "../../config/theme/selectors";
import CheckboxRoundChecked from "../svg/CheckboxRoundChecked";
import CheckboxRoundUnchecked from "../svg/CheckboxRoundUnchecked";

const CheckboxItemWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  align-items: center;
  grid-auto-columns: max-content auto;
  white-space: normal;
  word-break: break-word;
  text-align: left;

  @media screen and (max-width: 992px) {
    align-items: start;
  }

  & > span:nth-child(2) {
    font-weight: normal;
    font-size: 14px;
    line-height: 170%;
    color: ${({ checked }) => !checked && getColor("fgLight")};
  }
`;

const Checkbox = ({ checked, color, inverted, children, className }) => (
  <CheckboxItemWrapper className={className} checked={checked}>
    {checked ? (
      <CheckboxRoundChecked color={color} inverted={inverted} />
    ) : (
      <CheckboxRoundUnchecked />
    )}
    <span>{children}</span>
  </CheckboxItemWrapper>
);

export default Checkbox;
import { useEffect } from "react";
import { useLocation } from "react-router";

function useScrollToTop() {
  const { path } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);
}

export default useScrollToTop;

const maxStringLength = 50;
const maxTextAreaLength = 500;
const minStringLength = 3;

const withMsg = (test, msg) => (value) => {
  if (!value) {
    return undefined;
  }

  if (!test(value)) {
    return msg;
  }
  return undefined;
};

const composer = (validators) => (value) => {
  let error = undefined;
  for (let i = 0; i < validators.length; i++) {
    error = validators[i](value);
    if (error) {
      return error;
    }
  }
  return undefined;
};

const phone = withMsg(
  (value) =>
    /^\+?[0-9 ]+?\(?([0-9 ]+)\)?[-. ]?([0-9 ]+)[-. ]?([0-9 ]+)$/.test(value),
  "Invalid phone. Example of valid phone: +123 (045) 76-89-00"
);
const email = withMsg(
  (value) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    ),
  "Invalid email"
);
const minLength = (min) =>
  withMsg(
    (value) => value.length >= min,
    `Length must be more than ${min} character(s)`
  );
const maxLength = (max) =>
  withMsg(
    (value) => value.length <= max,
    `Length must be less than ${max} character(s)`
  );
const required = (value) => {
  if (!value) {
    return "Required";
  }

  return undefined;
};

export default {
  name: composer([
    required,
    minLength(minStringLength),
    maxLength(maxStringLength),
  ]),
  email: composer([
    required,
    email,
    minLength(minStringLength),
    maxLength(maxStringLength),
  ]),
  phone: composer([
    phone,
    minLength(minStringLength),
    maxLength(maxStringLength),
  ]),
  subject: composer([
    required,
    minLength(minStringLength),
    maxLength(maxStringLength),
  ]),
  message: composer([required, minLength(10), maxLength(maxTextAreaLength)]),
  text: composer([minLength(minStringLength), maxLength(maxStringLength)]),
  select: composer([required]),
};

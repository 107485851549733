import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LayoutPage from "layout/Page";
import {
  ColCenter,
  ContainerNarrow,
  LayoutColSingle,
  Separator,
  ColMax,
  LayoutColSingleMax,
} from "components/SectionLayout";
import {
  Wrapper as ArticleWideWrapper,
  Content as ArticleWideContent,
  Header as ArticleWideHeader,
} from "components/SectionArticleWide";
import { SvgBgBigCircle } from "../../components/svg/BigCircle";
import { SvgBgCircleBlue } from "../../components/svg/BgCirlceBlue";
import { SvgBgCircleOrange } from "../../components/svg/BgCirlceOrange";
import ContactUsForm from "components/ContactUsForm";
import LinkHref from "../../components/LinkHref";

const ContactUsPage = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <LayoutPage>
    {
      isMobile &&
        <>
        <SvgBgBigCircle size='30rem' top='-16.5rem' left='-7rem' />
        <SvgBgCircleBlue size='2rem' top='7rem' left='-0.9rem' />
        <SvgBgCircleOrange size='0.5rem' top='4rem' left='86%' />
        </>
    }
      <Separator />
      <ContainerNarrow>
        <LayoutColSingle>
          <ColCenter>
            <ArticleWideWrapper>
              <ArticleWideHeader>Contact us</ArticleWideHeader>
              <ArticleWideContent>
                <p>
                  Eager to learn more about our Open Banking solutions? Fill out
                  the contact form below or just drop a line to{" "}
                  <LinkHref href={"mailto:info@trustloop.io"} color="primary">
                    info@trustloop.io
                  </LinkHref>{" "}
                  and we'll be in touch
                </p>
              </ArticleWideContent>
            </ArticleWideWrapper>
          </ColCenter>
        </LayoutColSingle>
      </ContainerNarrow>
      <Separator />
      <ContainerNarrow>
        <LayoutColSingleMax>
          <ColMax>
            <ContactUsForm />
          </ColMax>
        </LayoutColSingleMax>
      </ContainerNarrow>
      <Separator />
    </LayoutPage>
  );
};

export default ContactUsPage;

import React, { useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ContextIsRouteMatched } from "../App.styles";

const AnimatedRouteComponent = ({ match, children }) => {
  const { setIsRouteMatched } = useContext(ContextIsRouteMatched);

  useEffect(() => {
    if (match != null) {
      setIsRouteMatched(true);
    }
  }, [setIsRouteMatched, match]);

  return (
    <CSSTransition
      in={match != null}
      timeout={300}
      classNames="page"
      unmountOnExit
    >
      <div className="page">
        {React.cloneElement(children, { ...children.props, match })}
      </div>
    </CSSTransition>
  );
};

const AnimatedRoute = ({ children, ...other }) => {
  return (
    <Route {...other}>
      {({ match }) => (
        <AnimatedRouteComponent match={match}>
          {children}
        </AnimatedRouteComponent>
      )}
    </Route>
  );
};

export default AnimatedRoute;

import React from "react";
import styled from "styled-components";
import * as mixins from "config/theme/mixins";

const Wrapper = styled.div`
  ${mixins.svg};
`;

const LogoWideLight = () => {
  return (
    <Wrapper>
      <svg
        width="148"
        height="31"
        viewBox="0 0 148 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.5028 23.0633C41.8984 22.3086 41.5966 21.356 41.5966 20.2039V12.1353H39.813V8.96447H41.6815L42.3329 4.77441H45.4754V8.96447H48.0516V12.1353H45.4754V19.8078C45.4754 20.6194 45.8809 21.0249 46.6925 21.0249H48.0516V23.743C47.7304 23.8559 47.3296 23.9593 46.8485 24.0545C46.367 24.1485 45.9189 24.1961 45.5037 24.1961C44.1067 24.1964 43.1065 23.8189 42.5028 23.0633Z"
          fill="white"
        />
        <path
          d="M50.0474 8.96501H53.3598L53.671 11.3712H53.8693C54.1901 10.522 54.6053 9.85258 55.115 9.36149C55.6245 8.87072 56.2942 8.62549 57.1251 8.62549C57.4833 8.62549 57.7998 8.65847 58.0733 8.72445C58.3466 8.79074 58.5215 8.8424 58.5974 8.88005V12.504H57.5213C56.2568 12.504 55.3416 12.8485 54.7752 13.5372C54.2091 14.2266 53.9259 15.2691 53.9259 16.6658V23.8568H50.0474V8.96501Z"
          fill="white"
        />
        <path
          d="M61.4085 22.9506C60.5309 22.1203 60.0921 20.7902 60.0921 18.9587V8.96533H63.9707V18.1381C63.9707 19.063 64.1872 19.733 64.6217 20.1482C65.0555 20.5633 65.6975 20.7709 66.5468 20.7709C67.4148 20.7709 68.1367 20.4647 68.7128 19.851C69.2882 19.2376 69.5761 18.4776 69.5761 17.572V8.96565H73.4543V23.8571H70.1418L69.859 21.904H69.6607C68.4526 23.4326 66.905 24.1969 65.0179 24.1969C63.4892 24.1963 62.2861 23.7812 61.4085 22.9506Z"
          fill="white"
        />
        <path
          d="M77.3475 22.9079C76.1677 22.0493 75.5777 20.9031 75.5777 19.4682L75.606 19.1283H79.3716V19.3266C79.3902 19.9876 79.6874 20.4637 80.2632 20.7566C80.8389 21.0491 81.5416 21.1951 82.3719 21.1951C83.1459 21.1951 83.8062 21.0917 84.354 20.8835C84.901 20.6763 85.1752 20.3364 85.1752 19.865C85.1752 19.3365 84.8964 18.9401 84.3399 18.6755C83.7829 18.4119 82.91 18.1477 81.7212 17.8832C80.4941 17.6 79.4939 17.3128 78.7199 17.0196C77.9459 16.7277 77.2756 16.2693 76.7098 15.6466C76.1437 15.0242 75.8606 14.1936 75.8606 13.1554C75.8606 11.7021 76.469 10.5836 77.6864 9.80028C78.9038 9.01729 80.4751 8.62549 82.3999 8.62549C84.1931 8.62549 85.6741 8.98898 86.8448 9.71533C88.015 10.4423 88.6 11.4282 88.6 12.674L88.572 13.325H84.8061V13.2684C84.7875 12.7213 84.5519 12.3108 84.0988 12.0369C83.6456 11.7634 83.032 11.6264 82.2583 11.6264C81.4843 11.6264 80.8716 11.7304 80.4181 11.9376C79.9653 12.1458 79.7385 12.4381 79.7385 12.8156C79.7385 13.2873 79.998 13.6365 80.5174 13.8631C81.0359 14.0896 81.8715 14.3159 83.0229 14.5424C84.287 14.8253 85.3162 15.1088 86.1088 15.3917C86.9015 15.6752 87.5902 16.1374 88.1755 16.7791C88.7606 17.4208 89.0531 18.2893 89.0531 19.3832C89.0531 22.5924 86.7785 24.1966 82.23 24.1966C80.1545 24.1966 78.5266 23.7675 77.3475 22.9079Z"
          fill="white"
        />
        <path
          d="M92.4764 23.0633C91.8727 22.3086 91.5708 21.356 91.5708 20.2039V12.1353H89.787V8.96447H91.6557L92.3071 4.77441H95.4496V8.96447H98.0258V12.1353H95.4496V19.8078C95.4496 20.6194 95.8548 21.0249 96.6671 21.0249H98.0258V23.743C97.7046 23.8559 97.3038 23.9593 96.8227 24.0545C96.3416 24.1485 95.8928 24.1961 95.478 24.1961C94.0806 24.1964 93.0807 23.8189 92.4764 23.0633Z"
          fill="white"
        />
        <path
          d="M102.49 3.38721H99.9985V23.8561H102.49V3.38721Z"
          fill="#B8B4B3"
        />
        <path
          d="M106.19 22.285C105.039 21.0113 104.463 19.0532 104.463 16.4107C104.463 13.7683 105.039 11.8102 106.19 10.5361C107.341 9.26204 109.059 8.625 111.343 8.625C113.626 8.625 115.344 9.26204 116.496 10.5361C117.646 11.8102 118.223 13.7683 118.223 16.4107C118.223 19.0532 117.646 21.0113 116.496 22.285C115.344 23.5591 113.626 24.1961 111.343 24.1961C109.058 24.1961 107.341 23.5591 106.19 22.285ZM114.626 20.8127C115.306 19.9354 115.646 18.5054 115.646 16.5237V16.2971C115.646 14.3154 115.306 12.8857 114.626 12.0078C113.947 11.1302 112.852 10.6914 111.343 10.6914C109.832 10.6914 108.738 11.1302 108.059 12.0078C107.379 12.8857 107.039 14.3154 107.039 16.2971V16.5237C107.039 18.5054 107.379 19.9354 108.059 20.8127C108.738 21.6903 109.832 22.1288 111.343 22.1288C112.852 22.1291 113.947 21.6906 114.626 20.8127Z"
          fill="#B8B4B3"
        />
        <path
          d="M121.169 22.285C120.017 21.0113 119.442 19.0532 119.442 16.4107C119.442 13.7683 120.017 11.8102 121.169 10.5361C122.32 9.26204 124.038 8.625 126.322 8.625C128.605 8.625 130.323 9.26204 131.475 10.5361C132.626 11.8102 133.202 13.7683 133.202 16.4107C133.202 19.0532 132.626 21.0113 131.475 22.285C130.323 23.5591 128.605 24.1961 126.322 24.1961C124.038 24.1961 122.32 23.5591 121.169 22.285ZM129.606 20.8127C130.286 19.9354 130.625 18.5054 130.625 16.5237V16.2971C130.625 14.3154 130.285 12.8857 129.606 12.0078C128.926 11.1302 127.831 10.6914 126.322 10.6914C124.811 10.6914 123.717 11.1302 123.038 12.0078C122.358 12.8857 122.018 14.3154 122.018 16.2971V16.5237C122.018 18.5054 122.358 19.9354 123.038 20.8127C123.717 21.6903 124.811 22.1288 126.322 22.1288C127.831 22.1291 128.926 21.6906 129.606 20.8127Z"
          fill="#B8B4B3"
        />
        <path
          d="M135.147 8.96484H137.015L137.327 10.9749H137.496C137.987 10.2013 138.633 9.61619 139.436 9.21972C140.238 8.82324 141.129 8.625 142.111 8.625C143.998 8.625 145.452 9.25768 146.471 10.5218C147.49 11.7868 148 13.7493 148 16.4107C148 19.0152 147.495 20.964 146.485 22.257C145.475 23.5498 144.093 24.1958 142.338 24.1958C141.281 24.1958 140.385 24.0352 139.648 23.7144C138.912 23.3938 138.298 22.8748 137.808 22.1571H137.638V28.754H135.147V8.96484ZM143.781 21.591C144.348 21.2332 144.762 20.6481 145.027 19.8358C145.291 19.0245 145.423 17.9204 145.423 16.5234V16.4104C145.423 14.9947 145.291 13.8722 145.027 13.0413C144.762 12.211 144.343 11.6116 143.767 11.2435C143.191 10.8753 142.432 10.6914 141.488 10.6914C140.167 10.6914 139.195 11.1728 138.572 12.1354C137.95 13.098 137.638 14.5233 137.638 16.4104V16.5234C137.638 18.3732 137.944 19.7699 138.558 20.7138C139.171 21.6573 140.148 22.1291 141.488 22.1291C142.451 22.1294 143.215 21.9502 143.781 21.591Z"
          fill="#B8B4B3"
        />
        <path
          d="M25.7809 9.26737C23.6021 9.26737 21.8295 7.49474 21.8295 5.316C21.8295 4.09452 22.3872 3.00095 23.2608 2.27553C21.0465 0.901871 18.4564 0.0787348 15.6832 0V5.91413C20.4848 6.13727 24.3506 10.0031 24.574 14.8049H30.4876C30.4178 12.3517 29.7634 10.0435 28.663 8.01041C27.9413 8.78158 26.918 9.26737 25.7809 9.26737Z"
          fill="#FAB833"
        />
        <path
          d="M14.8049 5.91351V0C12.0315 0.0784236 9.44133 0.901559 7.22711 2.27553C8.10066 3.00095 8.65834 4.09421 8.65834 5.316C8.65834 7.49474 6.88572 9.26706 4.70697 9.26706C3.56983 9.26706 2.54628 8.78158 1.8249 8.0101C0.724484 10.0435 0.0697099 12.3514 0 14.8046H5.91383C6.13696 10.0027 10.0031 6.13665 14.8049 5.91351Z"
          fill="#5BBDD7"
        />
        <path
          d="M5.91382 15.6831H0.000305176C0.0700151 18.1366 0.724476 20.4449 1.8249 22.4773C2.54658 21.7062 3.56982 21.2204 4.70697 21.2204C6.88571 21.2204 8.65834 22.993 8.65834 25.1717C8.65834 26.3932 8.10065 27.4868 7.22741 28.2125C9.44133 29.5862 12.0315 30.409 14.8046 30.4877V24.5739C10.0031 24.3511 6.13695 20.485 5.91382 15.6831Z"
          fill="#ED6C77"
        />
        <path
          d="M21.8295 25.1714C21.8295 22.9927 23.6021 21.2204 25.7808 21.2204C26.918 21.2204 27.9412 21.7059 28.6629 22.4773C29.7633 20.4442 30.4178 18.136 30.4875 15.6831H24.5737C24.3505 20.4847 20.4844 24.3508 15.6829 24.5736V30.4871C18.456 30.4084 21.0462 29.5859 23.2604 28.2119C22.3872 27.4865 21.8295 26.3929 21.8295 25.1714Z"
          fill="#B8B4B3"
        />
        <path
          d="M22.7073 5.31582C22.7073 7.01033 24.086 8.38897 25.7805 8.38897C27.4753 8.38897 28.8539 7.01033 28.8539 5.31582C28.8539 3.62131 27.4753 2.24268 25.7805 2.24268C24.086 2.24268 22.7073 3.62131 22.7073 5.31582Z"
          fill="#FAB833"
        />
        <path
          d="M25.7809 22.0981C24.0863 22.0981 22.7077 23.4771 22.7077 25.1713C22.7077 26.8658 24.0863 28.2444 25.7809 28.2444C27.4754 28.2444 28.854 26.8658 28.854 25.1713C28.854 23.4771 27.4754 22.0981 25.7809 22.0981Z"
          fill="#B8B4B3"
        />
        <path
          d="M4.70694 8.38928C6.40145 8.38928 7.78009 7.01064 7.78009 5.31613C7.78009 3.62131 6.40145 2.24268 4.70694 2.24268C3.01243 2.24268 1.63379 3.62131 1.63379 5.31613C1.63379 7.01033 3.01243 8.38928 4.70694 8.38928Z"
          fill="#5BBDD7"
        />
        <path
          d="M4.70694 28.2444C6.40145 28.2444 7.78009 26.8658 7.78009 25.1713C7.78009 23.4771 6.40145 22.0981 4.70694 22.0981C3.01243 22.0981 1.63379 23.4771 1.63379 25.1713C1.63379 26.8661 3.01243 28.2444 4.70694 28.2444Z"
          fill="#ED6C77"
        />
      </svg>
    </Wrapper>
  );
};

export default LogoWideLight;

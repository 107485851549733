/* eslint-disable no-undef */
const __ENV__ = process.env["REACT_APP_ENV"] || "qa";
const __IS_LOCAL__ = process.env["REACT_APP_IS_LOCAL"];
/* eslint-enable no-undef */

const beUrls = {
  qa: "https://dev-backend.open-banking-gateway.com",
  stage: "https://stage-backend.open-banking-gateway.com",
  local: "http://localhost:8080",
  prod: "https://prod-backend.trustloop.io",
};

const getBeUrl = () => {
  if (__IS_LOCAL__) {
    return beUrls["local"];
  }

  return beUrls[__ENV__];
};

const be = getBeUrl();

const customerUrls = {
  qa: "https://customer.qa.open-banking-gateway.com",
  stage: "https://customer.stage.open-banking-gateway.com",
  local: "http://localhost:8080",
  prod: "https://customer.trustloop.io",
};

const psuUrls = {
  qa: "https://psu.qa.open-banking-gateway.com",
  stage: "https://psu.stage.open-banking-gateway.com",
  local: "http://localhost:8080",
  prod: "https://psu.trustloop.io",
};

const config = {
  links: {
    customerSignIn: customerUrls[__ENV__] + "/sign_in",
    customerSignUp: customerUrls[__ENV__] + "/sign_up",
    psuSignIn: psuUrls[__ENV__] + "/sign_in",
  },
  endpoints: {
    notifyMe: `${be}/api/v0/marketing/notify`,
    contact: `${be}/api/v0/marketing/contact`,
  },
};

export default config;

import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LayoutPage from "../../../layout/Page";
import {
  ColCenter,
  Container,
  LayoutColSingle,
  Separator,
  ContainerNarrow,
} from "../../../components/SectionLayout";
import {
  Wrapper,
  Header,
  Content,
  SubContent,
  CardsWrapper,
  Card,
  Title,
} from "components/SectionFeaturesThree";
import {
  Wrapper as CheckOtherWrapper,
  Header as CheckOtherHeader,
  Content as CheckOtherContent,
  Product as CheckOtherProduct,
} from "components/SectionCheckOtherProducts";
import { SvgBgBigCircle } from 'components/svg/BigCircle';
import { SvgBgCircleBlue } from 'components/svg/BgCirlceBlue';
import useRedirect from "../../../hooks/useRedirect";
import CheckboxItem from "components/Checkbox";

const ProductsBankloopPage = () => {
  const redirectTo = useRedirect();
  const isMobile = useMediaQuery('(max-width: 992px)');

  return (
    <LayoutPage>
      {
        isMobile &&
          <>
            <SvgBgBigCircle top='-17rem' left='-3rem' size='30rem' />
            <SvgBgCircleBlue top='6rem' left='95%' size='2rem' />
          </>
      }
      <Separator />
      <Container padding={"0 0 0 0"}>
        <LayoutColSingle>
          <ColCenter>
            <Wrapper name="SectionFeaturesThree">
              <ContainerNarrow>
                <Header color={"primary"}>
                  <span>
                    <strong>bank</strong>loop
                  </span>
                  <span>
                    Efficient income verification with <strong>bank</strong>
                    loop. Backed by real-time bank data, it provides a true
                    understanding of your customer’s income level and sources.
                  </span>
                </Header>
              </ContainerNarrow>
              <Content columns={isMobile ? 1 : 2} color={"primary"} padding={"0 0 0 0"}>
                <SubContent>
                  <span>Product features</span>
                  <span>
                    <ul>
                      <li>
                        <CheckboxItem checked inverted color={"primary"}>
                          Up to 12-months of real-time bank transactions.
                        </CheckboxItem>
                      </li>
                      <li>
                        <CheckboxItem checked inverted color={"primary"}>
                          Analysis identifies sources of regular and irregular
                          income.
                        </CheckboxItem>
                      </li>
                      <li>
                        <CheckboxItem checked inverted color={"primary"}>
                          Identify joint accounts.
                        </CheckboxItem>
                      </li>
                    </ul>
                  </span>
                </SubContent>
                {
                  !isMobile &&
                    <SubContent>
                      <span>Use cases</span>
                      <span>
                        <ul>
                          <li>
                            <CheckboxItem checked inverted color={"primary"}>
                              Tenant Vetting
                            </CheckboxItem>
                          </li>
                          <li>
                            <CheckboxItem checked inverted color={"primary"}>
                              Credit Underwriting
                            </CheckboxItem>
                          </li>
                          <li>
                            <CheckboxItem checked inverted color={"primary"}>
                              Portfolio Monitoring
                            </CheckboxItem>
                          </li>
                          <li>
                            <CheckboxItem checked inverted color={"primary"}>
                              Vulnerability Checks
                            </CheckboxItem>
                          </li>
                        </ul>
                      </span>
                    </SubContent>
                }
              </Content>
            </Wrapper>
          </ColCenter>
        </LayoutColSingle>
      </Container>
      {
        isMobile &&
          <>
            <Separator mobileGap='4rem' />
            <Container padding={'0'}>
              <Title>Use cases</Title>
              <CardsWrapper>
                <Card>Tenant Vetting</Card>
                <Card>Credit Underwriting</Card>
                <Card>Portfolio Monitoring</Card>
                <Card>Vulnerability Checks</Card>
              </CardsWrapper>
            </Container>
          </>
      }
      <Separator mobileGap='4rem' />
      <Container>
        <LayoutColSingle>
          <ColCenter>
            <CheckOtherWrapper name="SectionCheckOtherProducts">
              <CheckOtherHeader>Check other products</CheckOtherHeader>
              <CheckOtherContent>
                <CheckOtherProduct
                  onClick={redirectTo("/products/bankloopplus")}
                  color={"third"}
                >
                  <span>
                    <strong>bank</strong>loop+
                  </span>
                  <span>Enhanced bank data</span>
                </CheckOtherProduct>
              </CheckOtherContent>
            </CheckOtherWrapper>
          </ColCenter>
        </LayoutColSingle>
      </Container>
    </LayoutPage>
  );
};

export default ProductsBankloopPage;

import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Container, LayoutColSingle } from 'components/SectionLayout'
import styled from 'styled-components'
import { absAfter } from '../../config/theme/mixins'
import { SvgBgBigCircle } from '../../components/svg/BigCircle'

const Wrapper = styled.div`
  width: 100%;
`;
const Title = styled.span`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 125%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 1281px) {
    font-size: 45px;
  }

  @media screen and (max-width: 1281px) {
    font-size: 31px;
  }
`;
const TitleSub = styled.span`
  max-width: 600px;
  margin: 1rem auto 0;
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 170%;
  display: block;
  text-align: center;
`;

const ContentWrapper = styled.div`
  margin-top: 3rem;
  display: grid;
  gap: 2rem 4rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;

  @media screen and (max-width: 992px) {
    grid-template: 1fr / 1fr;
    grid-gap: 30px;
  }
`;
const Item = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content max-content;
  text-align: left;

  @media screen and (max-width: 992px) {
    p {
      margin: 10px 0 0;
      font-size: 16px;
      line-height: 170%;
    }
  }
`;

const H = styled.span`
  font-family: Archivo;
  font-style: normal;
  font-weight: 600;
  font-size: 140px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: ${({ color }) => (color ? color : "auto")};
  position: relative;
  &::after {
    ${absAfter};
    width: 20px;
    height: 20px;
    background-color: ${({ color }) => (color ? color : "auto")};
    border-radius: 60px;
    left: 90px;
    top: 115px;
  }

  @media screen and (max-width: 992px) {
    font-size: 100px;
    line-height: 100%;

    &::after {
      top: 69px;
      left: 67px;
      width: 15px;
      height: 15px;
    }
  }
`;

const SectionHowDoesTrustLoopWorkNew = () => {
  const isDesktop = useMediaQuery('(min-width:992px)')

  return (
    <Container>
      { isDesktop && 
        <SvgBgBigCircle top={'-22rem'} left={'-20rem'} size={'50rem'} />
      }
      <LayoutColSingle>
        <Wrapper>
          <Title>How does TrustLoop work?</Title>
          <TitleSub>
            TrustLoop uses FCA regulated technology to create a secure loop of
            trust between <strong>you, your customer, their bank and TrustLoop.</strong>
          </TitleSub>
          <ContentWrapper>
            <Item>
              <H color={"#5bbdd7"}>1</H>
              <p>
                Your business asks TrustLoop to share your customer’s Open
                Banking data.
              </p>
            </Item>
            <Item>
              <H color={"#FAB833"}>2</H>
              <p>
                TrustLoop sends a consent request to your customer asking them
                to share their data with TrustLoop and for TrustLoop to share
                the data with you.
              </p>
            </Item>
            <Item>
              <H color={"#ED6C77"}>3</H>
              <p>
                TrustLoop forwards your customer to their bank where they
                authenticate with their usual online banking login details. This
                allows their bank to share their data with TrustLoop.
              </p>
            </Item>
            <Item>
              <H color={" #9F9A98"}>4</H>
              <p>
                Their bank sends your customer’s Open Banking data to TrustLoop,
                and we make it available to you.
              </p>
            </Item>
          </ContentWrapper>
        </Wrapper>
      </LayoutColSingle>
    </Container>
  )
}

export default SectionHowDoesTrustLoopWorkNew

import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LayoutColTwo } from "components/SectionLayout";
import { SvgBgBigCircle } from "../../components/svg/BigCircle";
import { SvgBgCircleBlue } from "../../components/svg/BgCirlceBlue";
import { SvgBgCircleOrange } from "../../components/svg/BgCirlceOrange";
import {
  ButtonGetStarted,
  ContainerStyled,
  HeaderMain,
  HeroSvgStyled,
  ColLeftStyled,
  ColStyled,
  P,
} from "./10-Section-Intro.styles";

const svgPositionDesktop = {
  SvgBgBigCircle: {
    top: '28rem',
    left: '-11rem',
    size: '30rem'
  },
  SvgBgCircleBlue: {
    top: '36rem',
    left: '3rem',
    size: '2rem'
  },
  SvgBgCircleOrange: {
    top: '26rem',
    left: '67rem',
    size: '4rem'
  }
}

const svgPositionMobile = {
  SvgBgBigCircle: {
    top: '-24rem',
    left: '-6rem',
    size: '30rem'
  },
  SvgBgCircleBlue: {
    top: '-1.2rem',
    left: '2rem',
    size: '1rem'
  },
  SvgBgCircleOrange: {
    top: '13.5rem',
    left: '88%',
    size: '1rem'
  }
}

const SectionIntro = () => {
  const isDesktop = useMediaQuery('(min-width:992px)');

  const svgPosition = isDesktop ? svgPositionDesktop : svgPositionMobile;

  return (
    <ContainerStyled>
      <SvgBgBigCircle {...svgPosition.SvgBgBigCircle} />
      <SvgBgCircleBlue {...svgPosition.SvgBgCircleBlue}  />
      <SvgBgCircleOrange {...svgPosition.SvgBgCircleOrange}  />
      <LayoutColTwo align={"flex-start"}>
        <ColLeftStyled>
          <HeaderMain>
            Instant <br /> Open Banking <br /> Solutions
          </HeaderMain>
          <P>
            TrustLoop uses Open Banking to enable your business to securely
            access your customers’ online bank account data with the minimum of
            fuss.
          </P>
          <P>
            Create an account and take advantage of our pay-as-you-go pricing
            model to buy prepaid credit bundles to get up and running straight
            away
          </P>
          {
            isDesktop &&
              <ButtonGetStarted>Get Started</ButtonGetStarted>
          }
        </ColLeftStyled>
        <ColStyled>
          <HeroSvgStyled />
        </ColStyled>
      </LayoutColTwo>
    </ContainerStyled>
  );
};

export default SectionIntro;

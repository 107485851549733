import React, { useContext } from "react";
import LayoutPage from "../../layout/Page";
import {
  ColCenter,
  ContainerNarrow,
  LayoutColSingle,
  Separator,
} from "../../components/SectionLayout";
import {
  Content as ArticleWideContent,
  Header as ArticleWideHeader,
  Wrapper as ArticleWideWrapper,
} from "../../components/SectionArticleWide";
import { ContextIsRouteMatched } from "../../App.styles";

const PageNotFound = () => {
  const { isRouteMatched } = useContext(ContextIsRouteMatched);

  if (isRouteMatched) return null;

  return (
    <LayoutPage>
      <Separator />
      <ContainerNarrow>
        <LayoutColSingle>
          <ColCenter>
            <ArticleWideWrapper>
              <ArticleWideHeader>Page Not Found</ArticleWideHeader>
              <ArticleWideContent> </ArticleWideContent>
            </ArticleWideWrapper>
          </ColCenter>
        </LayoutColSingle>
      </ContainerNarrow>
    </LayoutPage>
  );
};

export default PageNotFound;

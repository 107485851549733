import { useEffect } from "react";

/**
 * Hook that call `handleCustomAction` when clicks outside of the passed ref
 * and not on refSource (for example menu entry that open dropdown sub-menu)
 *
 * Example
 *
 * You have app top nav bar. There are button-link called `products`, which on click
 * toggle some dropdown list of products. So, by default, without `refSource` argument, when you click
 * to the `products` again, dropdown will close, because you clicked outside `ref` itself (where `ref` is
 * reference to our dropdown list), so `toggleDropdownList` custom action triggered. But, our `products` link have also
 * it's own action (also `toggleDropdownList`), so dropdown list will first close, and open again right after.
 *
 * So we need to pass also list of `refs`, click on which will be ignored
 */
function useOutsideClick(ref, refSource, handleCustomAction) {
  function handleClickOutside(event) {
    if (
      ref.current &&
      refSource.current &&
      !ref.current.contains(event.target) &&
      event.target !== refSource.current
    ) {
      handleCustomAction();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

export default useOutsideClick;

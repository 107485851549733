import { css } from "styled-components";
import theme from "./theme";
import { getColor, getColorByProps } from "./selectors";

/*
 * Layout
 * ======
 */

const bgDepOnColor = (color) => css`
  ${color === "secondary" &&
  css`
    color: ${({ theme }) => theme.colors.fg};
  `};
`;

export const containerShared = css`
  padding: 0 140px;
  position: relative;
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.desktop.containerMaxWidth};
  min-width: ${({ theme }) => theme.breakpoints.desktop.containerMinWidth};

  @media screen and (max-width: 1441px) {
    max-width: 100%;
    min-width: 100%;
    padding: 0 40px;
  }
`;

export const container = css`
  ${containerShared};
`;

export const containerWide = css`
  ${containerShared};
  width: 100%;
  max-width: 100%;
  padding: 0 0;

  @media screen and (max-width: 1441px) {
    padding: 0;
  }
`;

export const containerNarrow = css`
  ${containerShared};
  width: 100%;
  max-width: ${({ theme }) =>
    theme.breakpoints.desktop.containerNarrowMaxWidth};
  min-width: ${({ theme }) =>
    theme.breakpoints.desktop.containerNarrowMinWidth};

  @media screen and (max-width: 1281px) {
    max-width: 100%;
    min-width: 100%;
  }
`;

/*
 * Other
 * =====
 */

export const absAfter100wh = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: ${theme.zIndex.top};
`;

export const absAfter = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: ${theme.zIndex.top};
`;

export const absAfterCenterX = css`
  ${absAfter};
  left: 50%;
  transform: translateX(-50%);
`;

export const absAfterCenterY = css`
  ${absAfter};
  top: 50%;
  transform: translateY(-50%);
`;

export const absWidth = (width) => css`
  width: ${width};
  max-width: ${width};
  min-width: ${width};
`;
export const absHeight = (height) => css`
  height: ${height};
  max-height: ${height};
  min-height: ${height};
`;

export const absWidthHeight = (width, height) => css`
  width: ${width};
  max-width: ${width};
  min-width: ${width};
  height: ${height};
  max-height: ${height};
  min-height: ${height};
`;

export const round = (size) => css`
  width: ${size};
  height: ${size};
  border-radius: calc(${size} / 2);
`;

export const linkHorizontalBar = (color) => css`
  &::after {
    transition: all 300ms;
    ${absAfter};
    width: 0;
    background-color: ${color ? color : getColorByProps()};
    height: 2px;
    top: 100%;
    margin: 5px auto 0;
  }

  &:hover {
    &::after {
      width: 75%;
    }
  }
`;

export const svgBgCompSharedStyles = css`
  position: absolute;
  top: ${({ top }) => (top ? top : "0")};
  left: ${({ left }) => (left ? left : "0")};
  width: ${({ size }) => (size ? size : "10rem")};
  height: ${({ size }) => (size ? size : "10rem")};
  pointer-events: none;

  z-index: ${({ zIndex }) => (zIndex ? zIndex : "-1")};
`;

export const linkHorizontalBarStyled = css`
  a {
    position: relative;
    ${({ colorOnHover, active, color, noHorBar }) =>
      !active && !noHorBar && !colorOnHover
        ? linkHorizontalBar(
            active ? getColor("primary") : color && getColor(color)
          )
        : css`
            &:hover {
              color: ${({ theme }) => theme.colors.primary};
            }
          `};
  }
`;

export const button = css`
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.bg};
  padding: 0;
  margin: 0;
  transition: all 300ms;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    color: ${({ theme }) => theme.colors.bg};
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const arrowMidY = (color) => css`
  ${bgDepOnColor(color)};
  position: relative;
  &:after {
    ${absAfter};
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
  }
`;

export const arrowMidX = (color) => css`
  ${bgDepOnColor(color)};
  position: relative;
  &:after {
    ${absAfter};
    height: 0;
    width: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
  }
`;
export const arrowMidXTop = (size, color) => css`
  ${arrowMidX(color)};
  &:after {
    top: 0;
    margin-top: -${size};
    border-right: ${size} solid transparent;
    border-bottom: ${size} solid ${({ theme }) => theme.colors[color]};
    border-left: ${size} solid transparent;
  }
`;

export const arrowMidXBottom = (size, color) => css`
  ${arrowMidX(color)};
  &:after {
    top: 100%;
    margin-bottom: ${size};
    border-right: ${size} solid transparent;
    border-top: ${size} solid ${({ theme }) => theme.colors[color]};
    border-left: ${size} solid transparent;
  }
`;

export const arrowMidYLeft = (size, color) => css`
  ${arrowMidY(color)};
  &:after {
    left: 100%;
    margin-left: 0;
    border-bottom: ${size} solid transparent;
    border-top: ${size} solid transparent;
    border-left: ${size} solid ${({ theme }) => theme.colors[color]};
  }
`;

export const arrowMidYRight = (size, color) => css`
  ${arrowMidY(color)};
  &:after {
    left: 0;
    margin-left: -${size};
    border-bottom: ${size} solid transparent;
    border-top: ${size} solid transparent;
    border-right: ${size} solid ${({ theme }) => theme.colors[color]};
  }
`;

export const svg = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

import styled, { css } from "styled-components";
import { linkHorizontalBar } from "../../config/theme/mixins";

const LinkHref = styled.a`
  &,
  &:visited,
  &:focus {
    color: ${({ color, underline, active, theme }) => {
      if (active) {
        return theme.colors.primary;
      } else if (color) {
        return theme.colors[color];
      }
      return theme.colors.fg;
    }};
    text-decoration: none;
    ${({ underline }) =>
      underline &&
      css`
        //text-decoration: underline;
        text-decoration: none;
      `};
    cursor: pointer;
    transition: all 300ms;
    /*
    font-weight: ${({ weight, theme }) =>
      weight ? theme.fonts.weight[weight] : theme.fonts.weight.normal};
    */
  }
  line-height: 100%;
  position: relative;
  ${({ active, noHorBar }) => !active && !noHorBar && linkHorizontalBar()}
`;

export default LinkHref;

const colorsBranding = {
  dark: "#1A1A19",
  black: "#3C3C3B",
  white: "#fff",
  yellow: "#FAB833",
  red: "#ED6C77",
  gray: "#E9E8E7",
  grayLight: "#f8f7f7",
  grayDark: "#9F9A98",
  blue: "#5BBDD7"
};

export default colorsBranding;

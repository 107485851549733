import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Container, LayoutColSingle } from "components/SectionLayout";
import {
  Wrapper as FeaturesTwoWrapper,
  Content as FeaturesTwoContent,
  Header as FeaturesTwoHeader,
} from "components/SectionFeaturesTwo";
import {
  Content,
  Header,
  Wrapper as Planks,
  SubHeader,
} from "components/Planks";
import Plank from "components/Planks/Plank";
import HeaderWithSubHeader from "components/HeaderWithSubHeader";

const SectionFeaturesTwo = () => {
  const isMobile = useMediaQuery('(max-width:992px)');

  return (
    <Container padding={isMobile && '0'}>
      <LayoutColSingle>
        <FeaturesTwoWrapper>
          <FeaturesTwoHeader>
            <HeaderWithSubHeader>
              <span>Our product offering</span>
              <span>
                designed to help you harness the potential of Open Banking
              </span>
            </HeaderWithSubHeader>
          </FeaturesTwoHeader>
          <FeaturesTwoContent>
            <Planks>
              <Plank color="primary" href={"/products/bankloop"}>
                <Header color="primary">
                  <strong>bank</strong>loop
                </Header>
                <SubHeader>Verified bank data</SubHeader>
                <Content>
                  Efficient income verification with <strong>bank</strong>loop.
                  Backed by real-time bank data, it provides a true
                  understanding of your customer’s income level and sources.
                </Content>
              </Plank>
              <Plank color="third" href={"/products/bankloopplus"}>
                <Header color="third">
                  <strong>bank</strong>loop+
                </Header>
                <SubHeader>Enhanced bank data</SubHeader>
                <Content>
                  Deep financial insights with <strong>bank</strong>loop+. Using
                  transactional and behavioural insights, it provides a deep
                  understanding of your customer’s financial behaviour.
                </Content>
              </Plank>
            </Planks>
          </FeaturesTwoContent>
        </FeaturesTwoWrapper>
      </LayoutColSingle>
    </Container>
  );
};

export default SectionFeaturesTwo;

import styled from "styled-components";
import { withAlign } from "../SectionLayout";

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 2rem;
  justify-content: center;
  justify-items: center;
  ${withAlign}

  @media screen and (max-width: 992px) {
    grid-gap: 1rem;
  }
`;

export const Header = styled.div`
  font-weight: bold;
  font-size: 55px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  ${withAlign}

  @media screen and (max-width: 992px) {
    font-size: 31px;
  }
`;

export const Header2 = styled.div`
  font-weight: bold;
  font-size: 35px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  ${withAlign}

  @media screen and (max-width: 992px) {
    font-size: 24px;
  }
`;

export const Content = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 170%;
  text-align: center;
  gap: 1rem;
  display: grid;
  grid-auto-flow: row;
  & > * {
    margin: 0;
  }
  & > p {
    line-height: 170%;
  }
  ${withAlign};

  @media screen and (max-width: 992px) {
    font-size: 15px;
  }
`;

export default {
  Header,
  Content,
};

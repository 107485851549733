import React from "react";
import styled, { css } from "styled-components";
import { svg } from "../../config/theme/mixins";

const Wrapper = styled.div`
  ${svg};

  ${({ color, active, theme }) => {
    if (active) {
      return css`
        svg path {
          stroke: ${theme.colors.primary};
        }
      `;
    }
    if (color) {
      return css`
        svg path {
          stroke: ${theme.colors[color]};
        }
      `;
    }
  }};
`;

const ArrowRight = ({ active, color }) => {
  return (
    <Wrapper active={active} color={color}>
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.53564 9.07108L8.07118 5.53554L5.53564 2.00001"
          stroke="#9F9A98"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </Wrapper>
  );
};

export default ArrowRight;

import styled, { css } from "styled-components";
import * as mixins from "config/theme/mixins";

export const withAlign = ({ textAlign, align }) => css`
  align-items: center; /** By Y axis always centered */
  text-align: center;
  justify-items: center;
  justify-content: center;
  justify-self: center;
  ${align === "left" &&
  css`
    text-align: left;
    justify-items: flex-start;
    justify-content: flex-start;
    justify-self: flex-start;
  `};
  ${align === "right" &&
  css`
    text-align: right;
    justify-items: flex-end;
    justify-content: flex-end;
    justify-self: flex-end;
  `};
  ${align === "justify" &&
  css`
    text-align: justify;
    justify-items: stretch;
    justify-content: stretch;
    justify-self: stretch;
  `};
  ${textAlign === "justify" &&
  css`
    text-align: justify;
  `};
`;

export const withGap = ({ gap }) => css`
  gap: ${gap ? gap : "1rem"};
`;

const styleRows = css`
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: row;
  height: max-content;
  width: 100%;
  grid-auto-columns: 100%;
`;

export const ContainerWide = styled.div`
  ${mixins.containerWide};
  overflow: ${({ overflow }) => (overflow ? overflow : "visible")};
`;

export const ContainerNarrow = styled.div`
  ${mixins.containerNarrow};
  width: 100%;
  margin: 0 auto;
`;

export const Container = styled.div`
  ${mixins.container};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};

      @media screen and (max-width: 1281px) {
        padding: ${padding};
      }
    `};
`;

export const BlankWhiteSpaceHeight = styled.div`
  height: ${({ height }) => (height ? height : "1000px")};
  width: 100%;
`;

export const Separator = styled.div`
  height: ${({ gap }) => (gap ? gap : "5rem")};

  @media screen and (max-width: 992px) {
    height: ${({ mobileGap }) => (mobileGap ? mobileGap : "2rem")};
  }
`;

export const LayoutColTwo = styled.section`
  display: grid;
  grid-gap: 2rem;
  grid-template: 100% / 1fr 1fr;
  height: max-content;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "auto")};
  align-items: ${({ align }) => (align ? align : "center")};
  
  @media screen and (max-width: 992px) {
    grid-template: 1fr /1fr;
    gap: ${({ mobileGap }) => mobileGap ? mobileGap : '2rem'}
  }
`;

export const LayoutColThree = styled.section`
  display: grid;
  grid-gap: 1rem;
  grid-template: 100% / 1fr 1fr 1fr;
  height: max-content;
  align-items: center;
`;

const layoutColSingle = css`
  display: grid;
  grid-gap: 2rem;
  grid-template: 100% / 100%;
  height: max-content;
  align-items: center;
`;

export const LayoutColSingle = styled.section`
  ${layoutColSingle};
`;

export const LayoutColSingleMax = styled.section`
  ${layoutColSingle};
`;

export const Col = styled.div`
  ${styleRows};
`;

export const ColMax = styled.div`
  ${styleRows};
  width: 100%;
`;

export const ColLeft = styled.div`
  ${styleRows};
  text-align: left;
  justify-items: flex-start;
  justify-content: flex-start;
  align-items: center;
`;

export const ColCenter = styled.div`
  ${styleRows};
  text-align: center;
  justify-items: center;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
`;

export const Rows = styled.div`
  ${styleRows};
  ${withAlign};
  ${withGap};
  align-items: center;
`;

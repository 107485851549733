import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 10rem;
  height: max-content;
  align-items: center;
  grid-template: auto / 1fr 1fr;

  @media screen and (max-width: 1281px) {
    grid-gap: 5rem;
  }

  @media screen and (max-width: 992px) {
    grid-template: auto / 1fr;
    grid-gap: 0;
  }
`;

export const Header = styled.div`
  grid-area: header;
  font-size: 35px;
  line-height: 100%;
  text-align: left;
  color: ${({ color = "primary", theme }) => theme.colors[color]};

  @media screen and (max-width: 992px) {
    font-size: 31px;
  }
`;
export const SubHeader = styled.div`
  grid-area: sub-header;
  font-weight: normal;
  font-size: 15px;
  line-height: 170%;
  text-align: left;
  color: ${({ theme }) => theme.colors.fgLight};
`;

export const Content = styled.div`
  grid-area: content;
  text-align: left;
  padding-top: 1rem;

  @media screen and (max-width: 992px) {
    font-size: 15px;
    line-height: 170%;
    padding-top: .5rem;
  }
`;

export default {
  Wrapper,
  Header,
  SubHeader,
  Content,
};

import React from "react";
import styled from "styled-components";
import theme from "config/theme/theme";
import "./assets/css/animation.css";
import "config/theme/App.scss";

export const ContextIsRouteMatched = React.createContext({
  isRouteMatched: false,
  setIsRouteMatched: () => {},
});

export const AppStyled = styled.div`
  min-width: ${theme.breakpoints.desktop.appMinWidth};
  width: 100%;
  height: 100%;
  text-align: center;

  @media screen and (max-width: 1440px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

export const AnimatedRoutesContainer = styled.div`
  position: relative;
`;

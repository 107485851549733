import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LayoutPage from "layout/Page";
import {
  ColCenter,
  ContainerNarrow,
  LayoutColSingle,
  Separator,
} from "components/SectionLayout";
import {
  Wrapper as ArticleWideWrapper,
  Content as ArticleWideContent,
  Header as ArticleWideHeader,
} from "components/SectionArticleWide";
import { SvgBgBigCircle } from "../../components/svg/BigCircle";
import { SvgBgCircleBlue } from "../../components/svg/BgCirlceBlue";
import { SvgBgCircleRed } from "../../components/svg/BgCirlceRed";
import LinkHref from "../../components/LinkHref";
import PricingDesktop from './PricingDesktop';
import PricingMobile from './PricingMobile';

const PricingPage = () => {
  const isDesktop = useMediaQuery('(min-width: 992px)');

  return (
    <LayoutPage>
      {
        !isDesktop &&
          <>
            <SvgBgBigCircle top='-17rem' left='-6rem' size='30rem' />
            <SvgBgCircleBlue top='6rem' left='95%' size='2rem' />
            <SvgBgCircleRed top='7rem' left='2rem' size='0.5rem' />
          </>
      }
      <Separator />
      <ContainerNarrow>
        <LayoutColSingle>
          <ColCenter>
            <ArticleWideWrapper>
              <ArticleWideHeader>Pricing</ArticleWideHeader>
              <ArticleWideContent>
                <p>
                  Take advantage of our pay-as-you-go pricing model to buy
                  prepaid credit bundles to get up and running instantly with
                  our Open Banking products.
                </p>
                <p>
                  Think you’ll be using more credits?{" "}
                  <LinkHref
                    color={"primary"}
                    href={"https://trustloop.io/contact_us"}
                  >
                    Let's talk!
                  </LinkHref>{" "}
                  about enterprise pricing tailored to your business’s specific
                  needs.
                </p>
                <h3>Or try our products today for free!</h3>
              </ArticleWideContent>
            </ArticleWideWrapper>
          </ColCenter>
        </LayoutColSingle>
      </ContainerNarrow>
      <Separator />
      {
        isDesktop ?
          <PricingDesktop />
        : 
          <PricingMobile />
      }
      <Separator />
    </LayoutPage>
  );
};
export default PricingPage;

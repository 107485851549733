import React from "react";
import { Header, Content, Card, Image } from "components/Cards";
import { ReactComponent as Icon1 } from "assets/svg/frontPage/FeatureOne-1.svg";
import { ReactComponent as Icon2 } from "assets/svg/frontPage/FeatureOne-2.svg";
import { ReactComponent as Icon3 } from "assets/svg/frontPage/FeatureOne-3.svg";
import { ReactComponent as Icon4 } from "assets/svg/frontPage/FeatureOne-4.svg";
import { Cards } from "./50-Section-FeaturesOne";

const SectionFeaturesOneBusiness = () => {
  return (
    <Cards>
      <Card>
        <Header>Real-time insights</Header>
        <Content>
          TrustLoop is powered by real-time financial data and insights that
          give you a deep understanding of your customer.
        </Content>
        <Image>
          <Icon1 />
        </Image>
      </Card>
      <Card>
        <Header>Make decisions faster</Header>
        <Content>
          Gone are the days when you waited for your customers to fill in an
          income and expense form. Instead let TrustLoop instantly deliver
          real-time accurate financial data that you can rely on.
        </Content>
        <Image>
          <Icon2 />
        </Image>
      </Card>
      <Card>
        <Header>Contract-free pricing</Header>
        <Content>
          You can use TrustLoop without being tied to a contract. Our
          pay-as-you-go credits based software as a service (SaaS) model lets
          you plug and play straight away.
        </Content>
        <Image>
          <Icon3 />
        </Image>
      </Card>
      <Card>
        <Header>A partner you can trust</Header>
        <Content>
          TrustLoop is authorised and regulated by the Financial Conduct
          Authority. Cutting edge technology and robust security practises are
          at the heart of everything TrustLoop does.
        </Content>
        <Image>
          <Icon4 />
        </Image>
      </Card>
    </Cards>
  );
};

export default SectionFeaturesOneBusiness;

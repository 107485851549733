import React, { useCallback } from "react";
import formValidators from "../../utils/formValidators";
import { Form, Formik } from "formik";
import styled from "styled-components";
import TextField from "../TextField";
import ButtonSubmit from "../ButtonSubmit";
import { apiCallWrapper, post } from "../../utils/fetch";
import config from "../../config/config";
import { toast } from "react-toastify";

const NotifyMeForm = ({ onClose }) => {
  const onSubmit = useCallback(
    values => {
      apiCallWrapper({
        onStart: () =>
          console.debug(
            `ContactUsFrom --- Start. Values: ${JSON.stringify(values)}`
          ),
        apiCall: () => post(config.endpoints.notifyMe, values),
        onFinish: () => {
          toast.success("Thanks!");
          console.debug(`ContactUsFrom --- Success`);
          onClose();
        },
        onFail: () => {
          console.debug(`ContactUsFrom --- Fail`);
        },
        notify: true
      });
    },
    [onClose]
  );
  return (
    <NotifyMeFormStyled>
      <p>Please enter your email address and we will notify you</p>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          email: ""
        }}
      >
        <FormStyled>
          <TextField
            type="email"
            name="email"
            label="Email"
            style={{ gridArea: "email" }}
            required
            validate={formValidators.email}
          />
          <ButtonSubmit />
        </FormStyled>
      </Formik>
    </NotifyMeFormStyled>
  );
};

const FormStyled = styled(Form)`
  width: 100%;
  display: grid;
  grid-template:
    "email email" max-content
    "submit submit" max-content / 1fr 1fr;
  grid-gap: 3rem;
`;

const NotifyMeFormStyled = styled.div``;

export default NotifyMeForm;

import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Container, LayoutColTwo } from "components/SectionLayout";
import {
  Content,
  Wrapper as BubbleWrapper,
  SideContent,
} from "components/SectionDialogBubble";
import { SvgBgBigCircle } from "../../components/svg/BigCircle";
import { SvgBgCircleOrange } from "../../components/svg/BgCirlceOrange";
import { SvgBgCircleRed } from "../../components/svg/BgCirlceRed";

const SectionBubbleCuttingEdgeTech = () => {
  const isDesktop = useMediaQuery('(min-width:992px)');

  return (
    <Container padding={"0 0 0 0"}>
    {
      isDesktop &&
      <>
        <SvgBgBigCircle top={"-1rem"} left={"50rem"} size={"50rem"} />
        <SvgBgCircleOrange top={"40rem"} left={"10rem"} size={"2rem"} />
        <SvgBgCircleRed top={"25rem"} left={"63rem"} size={"4rem"} />
      </>
    }
      
      <LayoutColTwo>
        <BubbleWrapper left="true" color="third">
          <Content right="true">
            <h3>
              Cutting-edge technology coupled with decades of financial
              expertise
            </h3>
            <p>
              TrustLoop’s founders and directors have more than 70 combined
              years experience in financial services delivery with proven
              expertise in banking, debt servicing, regulated finance,
              brokering, technology solutions, risk management and more.
            </p>
            <p>
              This breadth of knowledge, skills and resources equips us to
              provide you business with simple yet innovative Open Banking
              services that you can rely on.
            </p>
          </Content>
        </BubbleWrapper>
        <SideContent left="true">
          <h3>Why trust TrustLoop?</h3>
        </SideContent>
      </LayoutColTwo>
    </Container>
  );
};

export default SectionBubbleCuttingEdgeTech;

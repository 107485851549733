import styled from "styled-components";
import heroOneImg from "assets/images/footer_get-started-now.png";
import config from "config/config";
import React from "react";
import * as mixins from "config/theme/mixins";

export const Wrapper = styled.div`
  background: linear-gradient(
      0deg,
      rgba(26, 26, 25, 0.8),
      rgba(26, 26, 25, 0.8)
    ),
    linear-gradient(0deg, #3c3c3b, #3c3c3b), url(${heroOneImg});
  background-position: top center;
  background-size: cover;
  background-blend-mode: normal, color, normal;
  min-height: 580px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.bg};

  @media screen and (max-width: 992px) {
    min-height: 498px;
  }
`;

export const Header = styled.div`
  font-weight: bold;
  font-size: 50px;
  max-width: 850px;
  line-height: 125%;
  padding: 1rem;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    font-size: 31px;
    padding: 0 40px;

    br {
      display: none;
    }
  }
`;

export const SubHeader = styled.div`
  margin: 0;
  padding: 2rem 0 0 0;

  @media screen and (max-width: 992px) {
    max-width: 190px;
    padding: 1rem 0 0 0;
  }
`;

const ButtonGetStartedWrapper = styled.div`
  ${mixins.button};

  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.bg};
  color: ${({ theme }) => theme.colors.primary};
  margin: 3rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 60px;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  border: 0;
  cursor: pointer;
`;

export const ButtonGetStarted = ({ children, ...rest }) => (
  <ButtonGetStartedWrapper
    {...rest}
    onClick={() => (window.location.href = config.links.customerSignUp)}
  >
    {children}
  </ButtonGetStartedWrapper>
);

export default {
  ButtonGetStarted,
  Wrapper,
  Header,
  SubHeader,
};

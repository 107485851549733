import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LayoutPage from "../../../layout/Page";
import {
  ColCenter,
  Container,
  LayoutColSingle,
  Separator,
  ContainerNarrow,
} from "../../../components/SectionLayout";
import {
  Wrapper,
  Header,
  Content,
  SubContent,
  CardsWrapper,
  Card,
  Title,
} from "components/SectionFeaturesThree";
import {
  Wrapper as CheckOtherWrapper,
  Header as CheckOtherHeader,
  Content as CheckOtherContent,
  Product as CheckOtherProduct,
} from "components/SectionCheckOtherProducts";
import { SvgBgBigCircle } from 'components/svg/BigCircle';
import { SvgBgCircleOrange } from 'components/svg/BgCirlceOrange';
import useRedirect from "../../../hooks/useRedirect";
import CheckboxItem from "components/Checkbox";

const ProductsIdloopPage = () => {
  const redirectTo = useRedirect();
  const isMobile = useMediaQuery('(max-width: 992px)');

  return (
    <LayoutPage>
      {
        isMobile &&
          <>
            <SvgBgBigCircle top='-17rem' left='-3rem' size='30rem' />
            <SvgBgCircleOrange top='6rem' left='95%' size='2rem' />
          </>
      }
      <Separator />
      <Container padding={"0 0 0 0"}>
        <LayoutColSingle>
          <ColCenter>
            <Wrapper name="SectionFeaturesThree">
              <ContainerNarrow>
                <Header color={"secondary"}>
                  <span>
                    <strong>id</strong>loop
                  </span>
                  <span>
                    Modernise customer authentication with <strong>id</strong>
                    loop. KYC made simpler, faster, more reliable, more secure.
                  </span>
                </Header>
              </ContainerNarrow>
              <Content columns={isMobile ? 1 : 2} color={"secondary"}>
                <SubContent>
                  <span>Product features</span>
                  <span>
                    <ul>
                      <li>
                        <CheckboxItem checked inverted color={"secondary"}>
                          Perform ID verification in real-time.
                        </CheckboxItem>
                      </li>
                      <li>
                        <CheckboxItem checked inverted color={"secondary"}>
                          Be sure your customer is who they say they are.
                        </CheckboxItem>
                      </li>
                      <li>
                        <CheckboxItem checked inverted color={"secondary"}>
                          Verify your customer owns a bank account.
                        </CheckboxItem>
                      </li>
                      <li>
                        <CheckboxItem checked inverted color={"secondary"}>
                          Confirm bank data matches other documents.
                        </CheckboxItem>
                      </li>
                    </ul>
                  </span>
                </SubContent>
                {
                  !isMobile &&
                    <SubContent>
                      <span>Use cases</span>
                      <span>
                        <ul>
                          <li>
                            <CheckboxItem checked inverted color={"secondary"}>
                              Know Your Customer
                            </CheckboxItem>
                          </li>
                        </ul>
                      </span>
                    </SubContent>
                }
              </Content>
            </Wrapper>
          </ColCenter>
        </LayoutColSingle>
      </Container>
      {
        isMobile &&
          <>
            <Separator mobileGap='4rem' />
            <Container padding={'0'}>
              <Title>Use cases</Title>
              <CardsWrapper>
                <Card>Know Your Customer</Card>
              </CardsWrapper>
            </Container>
          </>
      }
      <Separator mobileGap='4rem' />
      <Container>
        <LayoutColSingle>
          <ColCenter>
            <CheckOtherWrapper name="SectionCheckOtherProducts">
              <CheckOtherHeader>Check other products</CheckOtherHeader>
              <CheckOtherContent>
                <CheckOtherProduct
                  onClick={redirectTo("/products/bankloop")}
                  color={"primary"}
                >
                  <span>
                    <strong>bank</strong>loop
                  </span>
                  <span>Verified bank data</span>
                </CheckOtherProduct>
                <CheckOtherProduct
                  onClick={redirectTo("/products/bankloopplus")}
                  color={"third"}
                >
                  <span>
                    <strong>bank</strong>loop+
                  </span>
                  <span>Enhanced bank data</span>
                </CheckOtherProduct>
              </CheckOtherContent>
            </CheckOtherWrapper>
          </ColCenter>
        </LayoutColSingle>
      </Container>
    </LayoutPage>
  );
};

export default ProductsIdloopPage;

import styled, { css } from "styled-components";
import React from "react";
import { ReactComponent as BubbleArrowSvg } from "assets/svg/bubble-arrow.svg";

export const SideContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 2rem;
  font-size: 1.5rem;
  line-height: 200%;
  align-items: center;
  max-width: 400px;
  margin: ${({ left }) => (left ? "0 0 0 0" : "0 0 0 0")};
  justify-content: center;
  justify-self: center;

  h3 {
    font-size: 30px;
    line-height: 140%;

    @media screen and (max-width: 992px) {
      font-size: 20px;
    }

    @media screen and (max-width: 500px) {
      max-width: 270px;
    }
  }
`;

export const Content = styled.div(
  ({ right }) => css`
    display: grid;
    justify-content: flex-start;
    justify-items: flex-start;
    ul {
      list-style-position: outside;
      list-style-type: decimal;
      li {
        text-align: start;
      }
    }
    h3,
    p {
      text-align: start;
    }

    ${right &&
    css`
      justify-content: flex-end;
      justify-items: flex-end;
      ul {
        li {
          text-align: left;
        }
      }
      h3,
      p {
        text-align: left;
      }
    `};
    max-width: 460px;

    h3 {
      font-size: 30px;
      line-height: 150%;
      font-weight: 700;
    }

    @media screen and (max-width: 1281px) {
      max-width: 100%;
    }

    @media screen and (max-width: 992px) {
      h3 {
        font-size: 20px;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        line-height: 160%;
        font-weight: 500;
        margin: 0 0 1rem 0;
      }
    }
  `
);

export const ElemWithSvgArrow = ({ children, ...props }) => (
  <div {...props}>
    <BubbleArrowSvg className={"bubble-arrow"} />
    {children}
  </div>
);

export const Wrapper = styled(ElemWithSvgArrow)((props) => {
  const { theme, color = "primary", left } = props;

  let directionStyle = left
    ? css`
        justify-content: flex-end;
        border-radius: 3rem;
        @media screen and (max-width: 1281px) {
          border-radius: 0;
          border-bottom-right-radius: 3rem;
          border-top-right-radius: 3rem;
        }

        @media screen and (max-width: 992px) {
          order: 2;
        }
      `
    : css`
        justify-content: flex-start;
        border-radius: 3rem;
        @media screen and (max-width: 1281px) {
          border-radius: 0;
          border-bottom-left-radius: 3rem;
          border-top-left-radius: 3rem;
        }
      `;

  return css`
    position: relative;
    width: 100%;
    //width: 760px;
    @media screen and (max-width: 1281px) {
      //width: 650px;
    }
    background-color: ${theme.colors[color]};
    color: ${color === "fourth" || color === "secondary"
      ? theme.colors.fg
      : theme.colors.bg};
    ${({ left }) =>
      left
        ? css`
            padding: 50px 140px 50px 140px;

            @media screen and (max-width: 1281px) {
              padding: 30px 90px 30px 60px;
            }
          `
        : css`
            padding: 50px 140px 50px 140px;

            @media screen and (max-width: 1281px) {
              padding: 30px 60px 30px 90px
            }
          `};
    text-align: left;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem;
    align-items: center;
    ${directionStyle};

    @media screen and (max-width: 992px) {
      border-radius: 1.6rem;
      padding: 40px 40px 25px 40px;
    }

    svg {
      position: absolute;
      ${({ left }) =>
        left
          ? css`
              transform: rotate(180deg);
              right: -56px;
            `
          : css`
              transform: rotate(0deg);
              left: -56px;
            `};
      path {
        fill: ${theme.colors[color]};
      }

      @media screen and (max-width: 992px) {
        left: 50%;
        top: -87px;
        transform: translateX(-50%) rotate(90deg);
      }
    }
  `;
});

export default {
  Wrapper,
  SideContent,
  Content,
};

import React from "react";
import styled from "styled-components";
import { svg } from "config/theme/mixins";

const Wrapper = styled.div`
  ${svg};
`;

const CheckboxRoundUnchecked = () => {
  return (
    <Wrapper>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="11" cy="11" r="11" fill="#E9E8E7" />
      </svg>
    </Wrapper>
  );
};

export default CheckboxRoundUnchecked;

import React from "react";
import LayoutPage from "layout/Page";
import { Separator } from "components/SectionLayout";
import SectionIntro from "./10-Section-Intro";
import SectionBubbleWhyTakeAdvantage from "./30-Section-Bubble-WhyTakeAdvantage";
import SectionBubbleCuttingEdgeTech from "./40-Section-Bubble-CuttingEdgeTech";
import SectionFeaturesOne from "./50-Section-FeaturesOne";
import SectionFeaturesTwo from "./65-Section-FeaturesTwo";
import SectionHeroOne from "./70-Section-HeroOne";
import SectionHowDoesTrustLoopWorkNew from "./61-Section-HowDoesTrustLoopWork";
import SectionUseCasesNew from "./62-Section-UseCases";

const FrontPage = () => {
  return (
    <LayoutPage>
      <Separator />
      <SectionIntro />
      <Separator />
      <SectionBubbleWhyTakeAdvantage />
      <Separator mobileGap='3rem' />
      <SectionBubbleCuttingEdgeTech />
      <Separator mobileGap='4rem' />
      <SectionFeaturesOne />
      <Separator mobileGap='4rem' />
      <SectionHowDoesTrustLoopWorkNew />
      <Separator mobileGap='4rem' />
      <SectionUseCasesNew />
      <Separator />
      <SectionFeaturesTwo />
      <Separator mobileGap='0rem' />
      <SectionHeroOne />
    </LayoutPage>
  );
};

export default FrontPage;

import styled from "styled-components";
import { ReactComponent as SvgCircle } from "../../assets/svg/big-circle.svg";
import { svgBgCompSharedStyles } from "../../config/theme/mixins";

export const SvgBgBigCircle = styled(SvgCircle)`
  ${svgBgCompSharedStyles};
  fill: transparent;
  opacity: 0.9;

  path {
    stroke-width: ${({ strokeWidth }) => (strokeWidth ? strokeWidth : "2")};
    stroke: ${({ strokeColor }) => (strokeColor ? strokeColor : "#3c3c3b")};
  }
`;

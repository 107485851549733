import React from "react";
import styled from "styled-components";
import Transition from "react-transition-group/Transition";

const CSSTransitionFade = ({
  children,
  in: inProp,
  style,
  timeout = 300,
  ...other
}) => {
  const styles = {
    entering: {
      opacity: 0
    },
    entered: {
      opacity: 1
    }
  };

  return (
    <Transition unmountOnExit in={inProp} timeout={timeout} {...other}>
      {(state, childProps) => {
        return React.cloneElement(children, {
          style: {
            transition: `opacity ${timeout}ms`,
            opacity: 0,
            visibility: state === "exited" && !inProp ? "hidden" : undefined,
            ...styles[state],
            ...style,
            ...children.props.style
          },
          ...childProps
        });
      }}
    </Transition>
  );
};

export default styled(CSSTransitionFade)``;

import styled from "styled-components";
import * as mixins from "../../config/theme/mixins";
import React from "react";

const ButtonSubmit = styled((props) => (
  <button {...props} type={"submit"}>
    <div className={"wrapper"}>
      <span>Send</span>
    </div>
  </button>
))`
  ${mixins.button};
  font-family: Archivo, sans-serif;
  width: 260px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 1px;
  justify-self: center;
  grid-area: submit;
  box-shadow: 0 10px 20px rgba(60, 60, 59, 0.05);
  font-weight: 700;

  & > .wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-auto-flow: column;
    gap: 1rem;
  }

  &:hover {
    svg path {
      stroke: ${({ theme }) => theme.colors.bg};
    }
  }
`;

export default ButtonSubmit;

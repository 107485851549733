import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LayoutColSingle, ContainerWide } from "components/SectionLayout";
import {
  SubHeader,
  Wrapper,
  Header,
  ButtonGetStarted,
} from "components/SectionHeroOne";
import { SvgBgBigCircle } from "../../components/svg/BigCircle";
import { SvgBgCircleOrange } from "../../components/svg/BgCirlceOrange";
import { SvgBgCircleRed } from "../../components/svg/BgCirlceRed";
import { SvgBgCircleBlue } from "../../components/svg/BgCirlceBlue";

const svgPositionDesktop = {
  SvgBgBigCircle: {
    top: '-52rem',
    left: '-40rem',
    size: '100rem',
    zIndex: '100',
    strokeColor: 'white',
    strokeWidth: '1',
  },
  SvgBgCircleOrange: {
    top: '20rem',
    left: '20rem',
    size: '1.5rem',
    zIndex: '100',
  },
  SvgBgCircleRed: {
    top: '-1.5rem',
    left: '44rem',
    size: '3rem',
    zIndex: '100',
  },
  SvgBgCircleBlue: {
    top: '28rem',
    left: '49rem',
    size: '1.5rem',
    zIndex: '100',
  },
}

const svgPositionMobile = {
  SvgBgBigCircle: {
    top: '-15rem',
    left: '-10rem',
    size: '35rem',
    zIndex: '100',
    strokeColor: 'white',
    strokeWidth: '1',
  },
  SvgBgCircleOrange: {
    top: '15.5rem',
    left: '1rem',
    size: '1.5rem',
    zIndex: '100',
  },
  SvgBgCircleRed: {
    top: '-1.5rem',
    left: '15rem',
    size: '3rem',
    zIndex: '100',
  },
  SvgBgCircleBlue: {
    top: '26rem',
    left: '90%',
    size: '1.2rem',
    zIndex: '100',
  },
}

const SectionHeroOne = () => {
  const isDesktop = useMediaQuery('(min-width:992px)');

  const svgPosition = isDesktop ? svgPositionDesktop : svgPositionMobile;
  
  return (
    <ContainerWide overflow={"hidden"}>
      <SvgBgBigCircle
        {...svgPosition.SvgBgBigCircle}
      />
      <SvgBgCircleOrange
        {...svgPosition.SvgBgCircleOrange}
      />
      <SvgBgCircleRed
        {...svgPosition.SvgBgCircleRed}
      />
      <SvgBgCircleBlue
        {...svgPosition.SvgBgCircleBlue}
      />
      <LayoutColSingle>
        <Wrapper>
          <Header>
            Ready to begin using our <br /> Instant Open Banking Solutions?
          </Header>
          <SubHeader>You’re in the right place at the right time.</SubHeader>
          {
            isDesktop &&
              <ButtonGetStarted>Get started now</ButtonGetStarted>
          }
        </Wrapper>
      </LayoutColSingle>
    </ContainerWide>
  );
};

export default SectionHeroOne;

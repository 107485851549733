import React from 'react';
import styled from 'styled-components';
import config from "../../config/config";

const Title = styled.h3`
  font-size: 20px;
  margin-bottom: 1rem;
`

const Links = styled.div`
  display: grid;
  grid-auto-flow: row;
`

const Link = styled.a`
 color: ${({ theme }) => theme.colors.primary};
 padding: 1.5rem;
 font-size: 18px;
 text-decoration: none;
`

// const Separator = styled.div`
//   width: 100%;
//   height: 1px;
//   background-color: ${({ theme }) => theme.colors.fgLite};
// `

const SignInSubmenu = () => {
  return (
    <div>
      <Title>Sign in to your account</Title>
      <Links>
        {/* <Link href={ config.links.customerSignIn }>For business</Link>
        <Separator></Separator> */}
        <Link href={ config.links.psuSignIn }>For individuals</Link>
      </Links>
    </div>
  );
}

export default SignInSubmenu;

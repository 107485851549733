import styled from "styled-components";
import { Container, ColLeft, Col } from "components/SectionLayout";
import * as mixins from "config/theme/mixins";
import { ButtonGetStarted as ButtonGetStartedOrigin } from "../../components/SectionHeroOne";
import HeroSvg from "../../components/svg/HeroSvg";

export const ButtonGetStarted = styled(ButtonGetStartedOrigin)`
  box-shadow: 0 10px 20px rgba(60, 60, 59, 0.05);

  @media screen and (max-width: 992px) {
    justify-self: center;
    width: 100%;
    max-width: 300px;
    margin: 12px 0 8px;
  }
`;

export const ButtonClickToPlay = styled.div`
  ${mixins.button};
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 60px;
  letter-spacing: 1px;
  margin: 3rem 0 0 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  border: 0;
  box-shadow: 0 10px 20px rgba(60, 60, 59, 0.05);

  span {
    font-weight: 700;
  }

  &:hover {
    svg path {
      stroke: ${({ theme }) => theme.colors.bg};
    }
  }
`;

export const ContainerStyled = styled(Container)`
  display: grid;
  grid-auto-flow: row;
  gap: 2rem;
  position: relative;
`;

export const ColLeftStyled = styled(ColLeft)`
  @media screen and (max-width: 992px) {
    order: 2;
  }
`;

export const ColStyled = styled(Col)`
  @media screen and (max-width: 992px) {
    place-items: center;
  }
`;

export const HeaderMain = styled.h2`
  font-family: Archivo, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 125%;
  margin: 0;
  padding: 0 0 40px 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1281px) {
    padding: 0 0 25px 0;
    font-size: 40px;

    br {
      display: none;
    }
  }

  @media screen and (max-width: 992px) {
    padding: 0;
    font-size: 31px;
    line-height: 130%;
    justify-self: center;
    text-align: center;
  }
`;

export const P = styled.span`
  @media screen and (max-width: 992px) {
    font-size: 16px;
    line-height: 160%;
  }
`;

export const HeroSvgStyled = styled(HeroSvg)`
  width: 39rem;

  @media screen and (max-width: 1281px) {
    width: 28rem;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    max-width: 25rem;
    height: auto;
  }
`;

import React from "react";
import styled from "styled-components";
import Footer from "./Footer";
import * as mixins from "config/theme/mixins";
import useRedirect from "../../hooks/useRedirect";

const PlankWrapper = styled.div`
  position: relative;
  display: grid;
  grid-gap: 0.5rem;
  height: 100%;
  cursor: pointer;
  transition: all 300ms;
  grid-template:
    "header" max-content
    "sub-header" max-content
    "content" 1fr
    "footer" max-content / 100%;

  /* Shadow on hover */
  &::before {
    transition: all 300ms;
    opacity: 0;
    ${mixins.absAfter};
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
    top: -2rem;
    left: -2rem;
    width: 100%;
    height: 100%;
    padding: 2rem;
    pointer-events: none;
    border-radius: 0.5rem;
  }

  /* Horizontal bar */
  &::after {
    ${mixins.absAfter};
    background-color: ${({ theme }) => theme.colors.fgLite};
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 5rem;
    width: 1px;
    height: 110%;
  }

  &:nth-child(3n)::after {
    display: none;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .hor-bar {
      &::after {
        width: 83px;
      }
    }
  }

  @media screen and (max-width: 1281px) {
    &::after {
      left: 81%;
    }
  }

  @media screen and (max-width: 992px) {
    max-width: 500px;
    padding: 40px;

    &::after {
      width: 100%;
      height: 1px;
      transform: none;
      left: 0;
      top: 0;
      margin-left: 0;
    }

    &:nth-child(3n)::after {
      display: block;
    }
  }
`;

const Plank = ({ children, color = "primary", href }) => {
  const redirectTo = useRedirect();

  return (
    <PlankWrapper onClick={redirectTo(href)}>
      {children}
      <Footer color={color} href={href} />
    </PlankWrapper>
  );
};

export default Plank;

import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem 2rem;
  height: max-content;
  align-items: center;
  grid-template: auto / 1fr 1fr;

  @media screen and (max-width: 992px) {
    grid-template: 1fr / 1fr;
  }
`;
export const Card = styled.div`
  display: grid;
  grid-gap: 0.5rem 1rem;
  align-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  grid-template:
    "image header" max-content
    "image content" max-content / minmax(0, 100px) 1fr;
  
  @media screen and (max-width: 550px) {
    grid-template: "image" max-content
                    "header" max-content
                    "content" max-content
  }
`;
export const Header = styled.div`
  grid-area: header;
  font-weight: bold;
  font-size: 16px;
  line-height: 170%;
  text-align: left;
`;
export const Image = styled.div`
  grid-area: image;
  width: max-content;
`;

export const Content = styled.div`
  font-size: 15px;
  line-height: 170%;
  grid-area: content;
  text-align: left;
  display: grid;
  align-items: flex-start;
  align-content: flex-start;
  height: 100%;
  grid-auto-flow: row;
  gap: 1rem;
  & > * {
    margin: 0;
  }
`;

export default {
  Content,
  Image,
  Card,
  Header,
  Wrapper,
};

import React from "react";
import {
  ToastContainer as ReactToastContainer,
  cssTransition,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastFail from "../../components/svg/ToastFail";
import ToastSuccess from "../../components/svg/ToastSuccess";
import { FlexCenter } from "../index";
import styled from "styled-components";

const ToastContainer = (props) => {
  return (
    <ReactToastContainer
      {...props}
      transition={ToastFade}
      position={"top-center"}
      closeButton={<ToastCloseButton />}
    />
  );
};

const ToastCloseButton = ({ type, closeToast }) => {
  let button = <ToastSuccess />;
  switch (type) {
    case "success":
      button = <ToastSuccess />;
      break;
    case "error":
      button = <ToastFail />;
      break;
    default:
      button = <ToastSuccess />;
      break;
  }
  return <FlexCenter onClick={closeToast}>{button}</FlexCenter>;
};

const ToastFade = cssTransition({
  enter: "Toastify__fade-enter",
  exit: "Toastify__fade-exit",
  duration: [300, 300],
  appendPosition: false,
});

export default styled(ToastContainer)`
  .Toastify__fade-enter {
    animation-name: fadeInTop;
  }

  .Toastify__fade-exit {
    animation-name: fadeOutTop;
  }

  .Toastify__toast {
    align-items: center;
    padding: 1rem;

    &.Toastify__toast--success {
      background-color: #2ea077;
    }
    &.Toastify__toast--error {
      background-color: #eb5757;
    }
  }
`;

import React from "react";
import styled from "styled-components";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LayoutPage from "layout/Page";
import {
  BlankWhiteSpaceHeight,
  LayoutColSingle,
  Separator,
  Container,
} from "components/SectionLayout";
import {
  Wrapper as ArticleWideWrapper,
  Content as ArticleWideContent,
  Header as ArticleWideHeader,
} from "components/SectionArticleWide";
import { Rows } from "../../components/SectionLayout";
import { SvgBgBigCircle } from "../../components/svg/BigCircle";
import { SvgBgCircleBlue } from "../../components/svg/BgCirlceBlue";
import { SvgBgCircleRed } from "../../components/svg/BgCirlceRed";

const IntroTextWrapper = styled.div`
  padding: 0;
`;

export const PersonTitleWithJob = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: max-content;

  & > h3 {
    font-size: 30px;
    line-height: 150%;
    font-weight: 700;
    margin-bottom: 5px;
  }
  & > div:last-child {
    color: ${({ theme }) => theme.colorsBranding.grayDark};
  }

  @media screen and (max-width: 992px) {
    margin-bottom: 1.2rem;

    & > h3 {
      font-size: 22px;
      margin-bottom: 0;
    }

    & > div:last-child {
      font-size: 18px;
    }
  }
`;

const OurStoryPage = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)');

  return (
    <LayoutPage>
      {
        !isDesktop &&
          <>
          <SvgBgBigCircle top='-17rem' left='-6rem' size='30rem' />
          <SvgBgCircleBlue top='6rem' left='95%' size='2rem' />
          <SvgBgCircleRed top='7rem' left='2rem' size='0.5rem' />
          </>
      }
      <Separator />
      <Container>
        <LayoutColSingle>
          <Rows align={"left"} gap={"5rem"}>
            <ArticleWideWrapper>
              <ArticleWideHeader>Our Story</ArticleWideHeader>
              <IntroTextWrapper>
                <ArticleWideContent align={"left"}>
                  <p>
                    TrustLoop’s founders and directors have more than 70
                    combined years experience in financial services with proven
                    expertise in banking, regulated lending, technology
                    solutions, risk management and more.
                  </p>
                  <p>
                    Having spent many years working in financial services our
                    team have recognised the need for quick, cheap, efficient
                    solutions to some of the problems faced by our customers; be
                    it income verification, monitoring, collections or
                    identification. Traditionally some of these problems have
                    been solved manually: pen and paper combined with a level of
                    trust. The result ultimately being increased margins paid by
                    consumers.
                  </p>
                  <p>
                    Our team believes in the power of the data unlocked by Open
                    Banking, because it opens the door to efficient decision
                    making that ultimately benefits businesses and consumers
                    alike.
                  </p>
                </ArticleWideContent>
              </IntroTextWrapper>
            </ArticleWideWrapper>
          </Rows>
        </LayoutColSingle>
      </Container>
      <Separator />
      <BlankWhiteSpaceHeight height={"50px"} />
    </LayoutPage>
  );
};

export default OurStoryPage;

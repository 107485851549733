import styled, { css } from "styled-components";
import { container, containerWide } from "../../config/theme/mixins";
import { getColor } from "../../config/theme/selectors";
import * as mixins from "../../config/theme/mixins";

export const tabsCss = css`
  display: grid;
  grid-auto-flow: column;
  max-width: 800px;
  width: 100%;
  min-height: 50%;
  align-items: center;
  justify-content: space-around;
`;

export const tabCss = css`
  cursor: pointer;
  font-weight: normal;
  font-size: 30px;
  line-height: 140%;
  transition: all 300ms;
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors.primary};
  position: relative;

  /* Shadow on hover */
  &::before {
    transition: all 300ms;
    opacity: 0;
    ${mixins.absAfter};
    //box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
    top: -2rem;
    left: -3rem;
    width: 100%;
    height: 100%;
    padding: 2rem 3rem;
    border-radius: 8px;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  display: grid;
  grid-auto-flow: row;
  //grid-gap: 1rem;
  grid-gap: 0.1rem;
  span:first-child {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 150%;
  }
  span:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    color: ${getColor("fgLight")};
  }
`;

export const Wrapper = styled.div`
  ${containerWide};
  left: 0;
  right: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  height: 210px;
  background-color: ${({ theme }) => theme.colors.bg};
  z-index: ${({ theme }) => theme.zIndex.top};
  display: flex;
  justify-content: center;
  box-shadow: 0 50px 50px 5px rgba(0, 0, 0, 0.1);
`;

export const WrapperInner = styled.div`
  ${container};
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content 1fr;
  grid-auto-rows: 100%;
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  flex-flow: row nowrap;
  border-top: 1px solid ${({ theme }) => theme.colors.fgLite};
`;

export const Header = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 300px 1fr;
  grid-gap: 1rem;
  justify-items: flex-start;

  span:first-child {
    font-weight: bold;
    font-size: 30px;
    line-height: 140%;
  }
  span:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 170%;
    color: ${({ theme }) => theme.colors.fgLight};
  }
`;

export const Tabs = styled.div`
  ${tabsCss}
`;

export const Tab = styled.div`
  ${tabCss}
`;

import React from "react";
import crossIcon from "assets/images/cross.png";
import { Modal as MuiModal } from "@material-ui/core";
import styled, { css } from "styled-components";
import clsx from "clsx";

const Modal = ({ open, onClose, title, children, className, ...rest }) => {
  return (
    <MuiModal
      className={className}
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
      open={open}
      onClose={onClose}
      {...rest}
    >
      <div className={clsx("modal-frame")}>
        <div className={"button-close"} onClick={onClose}>
          <img
            alt="close-icon"
            src={crossIcon}
            className={"button-close-image"}
          />
        </div>
        <div className={"modal-body"}>{children}</div>
      </div>
    </MuiModal>
  );
};

export default styled(Modal)`
  .modal-frame {
    font-family: Archivo, Helvetica, sans-serif;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    min-width: 800px;
    min-height: 500px;
    max-height: 90vh;
    overflow: auto;
    background-color: #fcfcfc;
    padding: 0;
    outline: none;
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    grid-template-rows: min-content 1fr;
    border-radius: 0.25rem;
  }

  .modal-title {
    width: 100%;
    font-size: 1.25rem;
    font-weight: 300;
    padding: 1rem 3rem 2rem;
    border-bottom: 1px solid #ccc;
  }

  .button-close {
    height: 50px;
    width: 100%;
    z-index: 100;
  }

  .inner-text {
    font-size: 1.25rem;
    font-weight: 300;
    padding: 3rem;
  }

  .modal-body {
    font-size: 1rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    padding: 24px 100px 80px 100px;
  }

  .button-close-image {
    cursor: pointer;
    float: right;
    margin: 1rem;
    width: 20px;
    height: 20px;
  }

  ${({ small }) => {
    if (small) {
      return css`
        & .modal-frame {
          min-width: 500px;
          max-width: 500px;
          min-height: 150px;
        }

        & .modal-body {
          padding: 50px;
        }
      `;
    }
  }}
`;

import React, { useCallback } from "react";
import styled from "styled-components";
import { Form, Formik } from "formik";
import formValidators from "../../utils/formValidators";
import TextField from "../TextField";
import ButtonSubmit from "../ButtonSubmit";
import { apiCallWrapper, post } from "../../utils/fetch";
import config from "config/config";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 5rem;
  width: 100%;
  justify-items: center;
  padding: 1rem 0;

  @media screen and (max-width: 768px) {
    padding: 0 0 1rem;
  }
`;

const FormStyled = styled(Form)`
  width: 100%;
  display: grid;
  grid-template:
    "field field" max-content
    "field field" max-content
    "subject subject" max-content
    "message message" max-content
    "submit submit" max-content / 1fr 1fr;
  grid-gap: 3rem;

  @media screen and (max-width: 768px) {
    grid-template: 
      "field" max-content
      "field" max-content
      "field" max-content
      "field" max-content
      "subject" max-content
      "message" max-content
      "submit" max-content / 1fr;
    grid-gap: 1.5rem;
  }

  & label.MuiFormLabel-root {
    font-family: "Archivo", sans-serif;
    color: ${({ theme }) => theme.colorsBranding.grayDark};
    opacity: 0.8;
    top: -1rem;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }

    &.MuiInputLabel-shrink {
      transform: translate(0, 10px) scale(0.75);
      transform-origin: top left;
    }
  }
`;

const ContactUsForm = () => {
  const onSubmit = useCallback((values, actions) => {
    apiCallWrapper({
      onStart: () =>
        console.debug(
          `ContactUsFrom --- Start. Values: ${JSON.stringify(values)}`
        ),
      apiCall: () => post(config.endpoints.contact, values),
      onFinish: () => {
        toast.success("Messages sent successfully");
        console.debug(`ContactUsFrom --- Success`);
        actions.resetForm();
      },
      onFail: () => {
        console.debug(`ContactUsFrom --- Fail`);
      },
      notify: true,
    });
  }, []);

  return (
    <Wrapper>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          name: "",
          email: "",
          phone: "",
          company_name: "",
          message: "",
          subject: "",
        }}
      >
        <FormStyled>
          <TextField
            validate={formValidators.name}
            type="text"
            name="name"
            label="Name"
            required
          />
          <TextField
            type="email"
            name="email"
            label="Email"
            required
            validate={formValidators.email}
          />
          <TextField
            validate={formValidators.phone}
            type="text"
            name="phone"
            label="Phone"
          />
          <TextField
            validate={formValidators.text}
            type="text"
            name="company_name"
            label="Company name"
          />
          <TextField
            required
            multiline
            style={{ gridArea: "message" }}
            rows={4}
            validate={formValidators.message}
            type="area"
            name="message"
            label="Message"
          />
          <TextField
            required
            type="text"
            style={{ gridArea: "subject" }}
            name="subject"
            label="Subject"
            validate={formValidators.subject}
          />
          {/*<TextField
            select
            style={{ gridArea: "subject" }}
            name="subject"
            label="Subject"
            validate={formValidators.select}
          >
            <MenuItem key={"Subject 1"} value={"Subject 1"}>
              Subject 1
            </MenuItem>
            <MenuItem key={"Subject 2"} value={"Subject 2"}>
              Subject 2
            </MenuItem>
          </TextField>*/}
          <ButtonSubmit />
        </FormStyled>
      </Formik>
    </Wrapper>
  );
};

export default ContactUsForm;

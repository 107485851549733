import { useField } from "formik";
import { TextField as MuiTextField } from "@material-ui/core";
import React from "react";

const TextField = props => {
  const {
    label,
    children,
    multiline,
    rows,
    required,
    defaultValue,
    select,
    fullWidth,
    style
  } = props;
  const [field, meta] = useField(props); /* also, helpers */
  return (
    <MuiTextField
      fullWidth={fullWidth}
      style={style}
      defaultValue={defaultValue}
      required={!!required}
      multiline={!!multiline}
      label={label}
      rows={rows}
      select={!!select}
      {...field}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
    >
      {children}
    </MuiTextField>
  );
};

export default TextField;

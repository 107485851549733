import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LayoutPage from 'layout/Page';
import { Separator } from 'components/SectionLayout';
import FaqContent from './FaqContent';
import { SvgBgBigCircle } from 'components/svg/BigCircle';
import { SvgBgCircleBlue } from 'components/svg/BgCirlceBlue';

const Faq = () => {
  const isMobile = useMediaQuery('(max-width: 992px)');

  return (
    <LayoutPage>
      {
        isMobile &&
          <>
            <SvgBgBigCircle top='-20rem' left='-6rem' size='30rem' />
            <SvgBgCircleBlue top='5rem' left='85%' size='2rem' />
          </>
      }
      <Separator />
      <FaqContent />
    </LayoutPage>
  );
}

export default Faq;

import React, { useState } from "react";
import styled from "styled-components";
import LogoWideLight from "components/svg/LogoWideLight";
// import { ReactComponent as SocialIconFb } from "assets/svg/social-fb.svg";
// import { ReactComponent as SocialIconTw } from "assets/svg/social-tw.svg";
import { ReactComponent as SocialIconIn } from "assets/svg/social-in.svg";
import Link from "components/Link";
import { ContainerWide } from "components/SectionLayout";
import { Container } from "../SectionLayout";
import CSSTransitionFade from "../CSSTransitionFade";
import Modal from "../Modal";
import NotifyMeForm from "../NotifyMeForm";

const PleaseWaitAndFooterWrapper = styled.div`
  width: 100%;
`;

const SocialIcon = styled.span`
  transition: all 300ms;
  cursor: pointer;
  svg {
    transition: all 300ms;
    opacity: 0.3;
    circle {
      stroke: ${({ theme }) => theme.colors.bg};
    }
    path {
      fill: ${({ theme }) => theme.colors.bg};
    }
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

const FooterWrapper = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
  height: max-content;
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  grid-auto-columns: 100%;
  background-color: ${({ theme }) => theme.colors.bgDeep};
  justify-items: center;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 992px) {
    padding-top: 30px;
  }

  a,
  a:visited,
  a:focus {
    color: ${({ theme }) => theme.colors.bg};
  }
`;

const RowOne = styled.div`
  width: 100%;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.fg};
  height: max-content;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;

  @media screen and (max-width:992px) {
    grid-auto-flow: row;
    grid-gap: 35px;
  }
`;

const RowTwo = styled.div`
  padding-top: 2rem;
  height: max-content;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content 1fr max-content;
  font-size: 14px;
  line-height: 170%;

  @media screen and (max-width: 768px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;

const RowTwoLeft = styled.div`
  color: ${({ theme }) => theme.colors.fgLite};
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 1rem;

  @media screen and (max-width: 500px) {
    grid-template-rows: 1fr 1fr;
    text-align: left;
    grid-gap: 0.5rem;
    
    .dot {
      display: none;
    }

    span:first-child {
      grid-area: 2 / 1 / 3 / 3;
    }
  }
`;

/*
const RowTwoRight = styled.div`
  color: ${({ theme }) => theme.colors.fgLight};
  grid-auto-columns: max-content;
`;
*/

const RowOneLeft = styled.div``;

const RowOneMiddle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8rem;
  font-weight: 300;

  @media screen and (max-width: 768px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
`;

const Row = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  justify-items: ${({ justify }) => (justify ? justify : "center")};
  align-items: ${({ align }) => (align ? align : "center")};
`;

const RowSocIcons = styled(Row)`
  max-width: 200px;

  @media screen and (max-width: 768px) {
    max-width: 150px;
    order: -1;
  }
`;

const Col = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  justify-items: ${({ justify }) => (justify ? justify : "center")};
  align-items: ${({ align }) => (align ? align : "center")};

  @media screen and (max-width: 992px) {
    gap: ${({ mobileGap }) => mobileGap ? mobileGap : '1rem'}
  }
`;

const SmallTextUnderLogo = styled.span`
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 150%;
  color: #9f9a98;
  max-width: 320px;
  justify-content: space-between;
  text-align: left;
`;

const LinkMain = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: right;
  color: #ffffff;
  a {
    line-height: 100%;
  }
  margin-bottom: 1rem;

  @media screen and (max-width: 992px) {
    margin-bottom: 5px;
  }
`;

const LinkSub = styled(Link)`
  font-size: 14px;
  line-height: 100%;
  color: #ffffff;
  opacity: 0.8;
  a {
    line-height: 100%;
  }
  strong {
    line-height: 100%;
  }
`;

const LinkSubColor = ({ children, ...rest }) => (
  <LinkSub {...rest} color={"bg"}>
    {children}
  </LinkSub>
);

const Footer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <ContainerWide>
      <PleaseWaitAndFooterWrapper>
        {/*<LayoutColSingle>
          <PleaseBeWithUsWrapper>
            <PleaseBeWithUsContent>
              Please bear with us while we await FCA approval to act as an
              Account Information Service Provider
            </PleaseBeWithUsContent>
            <LinkNotifyMe onClick={() => setModalIsOpen(true)}>
              Notify me
            </LinkNotifyMe>
          </PleaseBeWithUsWrapper>
        </LayoutColSingle>*/}
        <FooterWrapper>
          <Container>
            <RowOne>
              <RowOneLeft>
                <Col gap={"2rem"} justify={"flex-start"}>
                  <LogoWideLight />
                  <SmallTextUnderLogo>
                    TrustLoop is a trading name of Open B Gateway Limited
                    (Company Number: 11910674). We are authorised and regulated
                    by the FCA under the Payment Services Regulations 2017 as an
                    Account Information Service Provider (FRN: 913211).
                  </SmallTextUnderLogo>
                </Col>
              </RowOneLeft>
              <RowOneMiddle>
                <Col justify={"flex-start"} gap={".75rem"} mobileGap={".5rem"}>
                  <LinkMain>Company</LinkMain>
                  <LinkSubColor to="/our_story">Our story</LinkSubColor>
                  <LinkSubColor to="/pricing">Pricing</LinkSubColor>
                  <LinkSubColor to="/contact_us">Contact us</LinkSubColor>
                </Col>
                <Col justify={"flex-start"} gap={".75rem"} mobileGap={".5rem"}>
                  <LinkMain>Products</LinkMain>
                  <LinkSubColor to="/products/bankloop">
                    <strong>bank</strong>loop
                  </LinkSubColor>
                  <LinkSubColor to="/products/bankloopplus">
                    <strong>bank</strong>loop+
                  </LinkSubColor>
                </Col>
                {/*
                <Col justify={"flex-start"} gap={".75rem"}>
                  <LinkMain>Developers</LinkMain>
                  <LinkSub>API Documentation</LinkSub>
                </Col>
                */}
                <Col justify={"flex-start"} gap={".75rem"} mobileGap={".5rem"}>
                  <LinkMain>Get in touch</LinkMain>
                  <LinkSubColor href="mailto:info@trustloop.io">
                    info@trustloop.io
                  </LinkSubColor>
                  <LinkSubColor href="tel:+443301330506">
                    +44 01582 3462
                  </LinkSubColor>
                </Col>
              </RowOneMiddle>
            </RowOne>
            <RowTwo>
              <RowTwoLeft>
                <span>© 2020 by trustloop</span>
                <span className='dot'>•</span>
                <Link color={"bg"} to={"/terms-of-use"}>
                  Terms of use
                </Link>
                <span className='dot'>•</span>
                <Link color={"bg"} to={"/privacy-policy"}>
                  Privacy Policy
                </Link>
              </RowTwoLeft>
              <div> </div>
              <RowSocIcons justify='start'>
                {/* <SocialIcon>
                  <SocialIconFb />
                </SocialIcon>
                <SocialIcon>
                  <SocialIconTw />
                </SocialIcon> */}
                <SocialIcon>
                  <Link href='https://linkedin.com/company/trustloop'>
                    <SocialIconIn />
                  </Link>
                </SocialIcon>
              </RowSocIcons>
            </RowTwo>
          </Container>
        </FooterWrapper>
      </PleaseWaitAndFooterWrapper>
      <CSSTransitionFade in={modalIsOpen}>
        <Modal
          disableScrollLock={true}
          open={true}
          onClose={() => setModalIsOpen(false)}
        >
          <NotifyMeForm onClose={() => setModalIsOpen(false)} />
        </Modal>
      </CSSTransitionFade>
    </ContainerWide>
  );
};

export default Footer;

import React from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import config from "../../config/config";
import DecoBarVertical from "../DecoBarVertical";
import { Header, Tab, Tabs, Wrapper, WrapperInner } from "./DropDown";

const DropDownSignIn = ({ close, refSource }) => {
  const wrapperRef = React.useRef(null);
  useOutsideClick(wrapperRef, refSource, close);

  return (
    <Wrapper ref={wrapperRef}>
      <WrapperInner>
        <Header>
          <span>Sign In</span>
          <span>Sign in to your account</span>
        </Header>
        <Tabs>
          <Tab
            onClick={() => (window.location.href = config.links.customerSignIn)}
          >
            for business
          </Tab>
          <DecoBarVertical />
          <Tab onClick={() => (window.location.href = config.links.psuSignIn)}>
            for individuals
          </Tab>
        </Tabs>
      </WrapperInner>
    </Wrapper>
  );
};

export default DropDownSignIn;

import React from "react";
import { Header, Content, Card, Image } from "components/Cards";
import { ReactComponent as Icon5 } from "assets/svg/frontPage/FeatureOne-5.svg";
import { ReactComponent as Icon6 } from "assets/svg/frontPage/FeatureOne-6.svg";
import { ReactComponent as Icon7 } from "assets/svg/frontPage/FeatureOne-7.svg";
import { ReactComponent as Icon8 } from "assets/svg/frontPage/FeatureOne-8.svg";
import { Cards } from "./50-Section-FeaturesOne";

const SectionFeaturesOneIndividuals = () => {
  return (
    <Cards>
      <Card>
        <Header>Consent dashboard</Header>
        <Content>
          Total transparency and control. TrustLoop creates your very own
          account through which you can view, refresh and revoke your consents
          and also view the bank data that you’ve shared.
        </Content>
        <Image>
          <Icon5 />
        </Image>
      </Card>
      <Card>
        <Header>Worry-free data sharing</Header>
        <Content>
          TrustLoop is authorised and regulated by the Financial Conduct
          Authority. Cutting edge technology and robust security practises are
          at the heart of everything TrustLoop does.
        </Content>
        <Image>
          <Icon6 />
        </Image>
      </Card>
      <Card>
        <Header>Be treated fairly, as an individual</Header>
        <Content>
          Your banking data can say a lot about you as an individual, which can
          allow for products and services to be tailored to your specific needs
          and circumstances.
        </Content>
        <Image>
          <Icon7 />
        </Image>
      </Card>
      <Card>
        <Header>Save time</Header>
        <Content>
          Gone are the days when you needed to fill in endless paper forms,
          upload bank statements or payslips. TrustLoop can sidestep these
          old-fashioned processes so you get where you want to be faster.
        </Content>
        <Image>
          <Icon8 />
        </Image>
      </Card>
    </Cards>
  );
};

export default SectionFeaturesOneIndividuals;

import styled from "styled-components";

const H3 = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 140%;
`;

export default H3;

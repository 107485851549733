import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import config from "config/config";
import LogoWide from 'components/svg/LogoWide';
import ArrowRight from 'components/svg/ArrowRight';
// import LinkHref from 'components/LinkHref';
import Link from 'components/Link';
import ProductsSubmenu from './ProductsSubmenu';
import SignInSubmenu from './SignInSubmenu';

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 201;
`;

const SidebarWrapper = styled.div`
  display: grid;
  place-items: center;
  grid-auto-flow: row;
  grid-template-rows: max-content 1fr max-content;
  height: calc(100% - 50px);
  margin-top: 50px;
`;

const LinksWrapper = styled.div`
  position: static;
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  transform: none;
  transition: transform 400ms cubic-bezier(0.34, 1.56, 0.64, 1);

  .submenuOpen & {
    position: absolute;
    top: 83px;
    place-content: center;
    height: calc(100% - 163px);
    background-color: #fff;
    transform: translateX(-100%);
    transition: transform 300ms ease-in;
    z-index: 1;
  }
`;

const LinkStyled = styled(Link)`
  padding: 5px 0;
`;

// const LinkHrefStyled = styled(LinkHref)`
//   width: 200px;
//   padding: 12px 0;
//   border: 2px solid ${({ theme }) => theme.colors.primary};
// `;

const LinkWithArrow = styled.span`
  padding: 5px 0;
  cursor: pointer;

  svg {
    position: relative;
    top: 1px;
    left: 4px;
  }
`;

const MenuButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px 30px 30px;
  color: ${({ theme }) => theme.colors.primary};
  transition: all .3s;

  .submenuOpen & {
    width: 100%;
  }

  .MuiFab-root {
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    transition: all .3s;

    &:hover {
      background-color: #ffffff;
    }
  }
`;

const Menu = ({ open, submenu, toggleOpen, setSubmenu }) => {
  return (
    <Sidebar className={ clsx(submenu && 'submenuOpen') }>
      <SidebarWrapper>
        <LogoWide width='155px' />
        <LinksWrapper>
          <LinkStyled to="/">
            Home
          </LinkStyled>
          <LinkStyled to="/our_story" >
            Our story
          </LinkStyled>
          <LinkWithArrow onClick={ () => setSubmenu('products') }>
            Products
            <ArrowRight color='fg' />
          </LinkWithArrow>
          <LinkStyled to="/pricing" >
            Pricing
          </LinkStyled>
          <LinkStyled to="/faq" >
            FAQ
          </LinkStyled>
          <LinkStyled to="/contact_us" >
            Contact us
          </LinkStyled>
          <LinkWithArrow onClick={ () => setSubmenu('signIn') }>
            Sign in
            <ArrowRight color='fg' />
          </LinkWithArrow>
          {/* <LinkHrefStyled href={ config.links.customerSignUp } >
            Get started
          </LinkHrefStyled> */}
        </LinksWrapper>
        {
          submenu === 'products' &&
            <ProductsSubmenu />
        }
        {
          submenu === 'signIn' &&
            <SignInSubmenu />
        }

        <MenuButtons>
          <Fab color='inherit' onClick={ () => toggleOpen() }>
            { !open ? <MenuIcon /> : <CloseIcon /> }
          </Fab>
          { 
            submenu &&
            <Fab color='inherit' onClick={ () => setSubmenu('') }>
              <ArrowBackIcon />
            </Fab> 
          }
        </MenuButtons>
      </SidebarWrapper>
    </Sidebar>
  );
}

export default Menu;

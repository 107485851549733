import React from "react";
import styled from "styled-components";
import { svg } from "config/theme/mixins";
import { getColorByProps } from "../../config/theme/selectors";

const Wrapper = styled.div`
  ${svg};

  svg circle {
    fill: ${({ inverted }) => inverted ? 'white' : getColorByProps()}
  }

  svg path {
    stroke: ${({ inverted }) => inverted ? getColorByProps() : 'white'}
  }
`;

const CheckboxRoundChecked = ({ color, inverted }) => {
  return (
    <Wrapper color={color} inverted={inverted}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="11" cy="11" r="11" />
        <path d="M7 11L10.5 14L15.5 8" stroke="white" strokeWidth="2" />
      </svg>
    </Wrapper>
  );
};

export default CheckboxRoundChecked;

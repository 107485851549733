import colorsBranding from "./colorBranding";

const maxWidth = "1440px";

const theme = {
  zIndex: {
    overTheTop: 200,
    top: 100,
    normal: 0,
  },
  colorsBranding,
  fonts: {
    weight: {
      light: 400,
      normal: 500,
      semiBold: 600,
      bold: 700,
    },
  },
  colors: {
    primary: colorsBranding.blue,
    secondary: colorsBranding.yellow,
    third: colorsBranding.red,
    fourth: colorsBranding.gray,
    fg: colorsBranding.black,
    fgLite: colorsBranding.gray,
    fgLight: colorsBranding.grayDark,
    bgAlt: colorsBranding.grayLight,
    bg: colorsBranding.white,
    bgDeep: colorsBranding.dark,
    bgDark: colorsBranding.dark,
  },
  layout: {},
  breakpoints: {
    desktop: {
      appBarMaxWidth: maxWidth,
      appWidth: "100%",
      appMinWidth: maxWidth,
      appBarLinksMiddleMaxWidth: "100%",
      appBarHeight: "100px",
      containerNarrowMaxWidth: "1000px",
      containerNarrowMinWidth: "1000px",
      containerMaxWidth: maxWidth,
      containerMinWidth: maxWidth,
    },
  },
};

export default theme;

import styled from "styled-components";

export const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 140%;
  text-align: center;
  padding: 0 30px;
`;

export const Content = styled.div`
  margin-top: 2rem;
  padding: 1rem;

  @media screen and (max-width: 992px) {
    padding: 0;
    margin-top: 40px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 100%;
  grid-gap: 3rem;
  height: max-content;
  align-items: center;

  @media screen and (max-width: 992px) {
    grid-gap: 0;
  }
`;

export default {
  Wrapper,
  Content,
  Header,
};

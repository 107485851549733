import styled from "styled-components";
import React from "react";
import * as mixins from "config/theme/mixins";

const FooterWrapper = styled.div`
  grid-area: footer;
  display: grid;
  padding-top: 3rem;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr;

  @media screen and (max-width: 992px) {
    padding-top: 2rem;
  }
`;

export const LinkLearnMore = styled.a`
  color: ${({ theme }) => theme.colorsBranding.primary};
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.05em;
  text-decoration: none;
  transition: all 300ms;
  color: ${({ color, theme }) => theme.colors[color]};
  cursor: pointer;

  @media screen and (max-width: 992px) {
    justify-self: end;
  }
`;

const HorizontalBar = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  &::after {
    border-radius: 100px;
    transition: all 200ms;
    ${mixins.absAfter};
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 63px;
    background-color: ${({ color, theme }) => theme.colors[color]};
    height: 5px;
  }
`;

export const Footer = ({ color }) => {
  return (
    <FooterWrapper>
      <HorizontalBar className={"hor-bar"} color={color} />
      <LinkLearnMore color={color}>Learn more</LinkLearnMore>
    </FooterWrapper>
  );
};

export default Footer;

import React from "react";
import styled, { css } from "styled-components";
import * as mixins from "../../config/theme/mixins";

const Wrapper = styled.div`
  ${mixins.svg};
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ active, theme }) => {
    if (active) {
      return css`
        svg path {
          stroke: ${theme.colors.primary};
        }
      `;
    }
  }};
`;

const ArrowDown = ({ active }) => {
  return (
    <Wrapper active={active}>
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.92871 5.54004L5.46424 8.07557L8.99978 5.54004"
          stroke="#3C3C3B"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </Wrapper>
  );
};

export default ArrowDown;

import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { getColor, getColorByProps } from "../../config/theme/selectors";
import ExpandIcon from 'components/svg/ExpandIcon';
import ArrowRight from "../../components/svg/ArrowRight";
import LinkHref from "../../components/LinkHref";
import CheckboxItem from 'components/Checkbox';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0 10px',
    '& .MuiAccordion-root': {
      boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.05)',
      borderRadius: '10px',
      margin: '10px 0',
      '&.Mui-expanded': {
        margin: '10px 0'
      },
      '&::before': {
        display: 'none'
      },
      '& .MuiAccordionSummary-content': {
        margin: '20px 0'
      },
      '& .MuiAccordionSummary-root': {
        padding: '0 30px'
      },
      '& .MuiAccordionDetails-root': {
        padding: '0 30px',
        flexDirection: 'column'
      }
    }
  },
}));

const PricingPlanTitle = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0;
  grid-auto-rows: max-content;
  width: 100%;
  height: 100%;
  grid-auto-columns: 100%;
  justify-items: flex-start;

  & > span:first-child {
    font-style: normal;
    font-size: 35px;
    line-height: 150%;
    color: ${getColorByProps()};
  }

  & > span:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 170%;
    color: ${getColor("fgLight")};
  }
`;

const PricingPlanFeaturesList = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  justify-content: center;
  width: 100%;
  grid-auto-columns: 100%;
  word-break: break-all;
  overflow: hidden;
  padding-bottom: 1.5rem;
`;

const PricingPlanInfo = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  min-height: 86px;
  margin: 0 -30px;
  padding: 17px 30px;
  border-top: 1px solid ${getColor("fgLite")};

  & > span {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    text-align: left;
  }
`;

const InfoCell = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  align-content: center;
  font-size: 15px;
  text-align: right;

  & > span {
    line-height: 170%;
  }

  & > span:nth-child(2) {
    color: ${getColor("fgLight")};
  }
`;

const LetsTalkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  height: 100%;
  align-content: center;
  cursor: pointer;

  & > span:first-child {
    margin-right: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 170%;
    color: ${getColorByProps()};
  }
`;

const LetsTalk = ({ color }) => (
  <LinkHref href={"https://trustloop.io/contact_us"}>
    <LetsTalkWrapper color={color}>
      <span>Let's talk</span>
      <span>
        <ArrowRight color={color} />
      </span>
    </LetsTalkWrapper>
  </LinkHref>
);

const PricingMobile = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandIcon color={"secondary"} />}
          aria-controls="idloop-panel"
        >
          <PricingPlanTitle color={"secondary"}>
            <span>
              <strong>id</strong>loop
            </span>
            <span>ID Verification</span>
          </PricingPlanTitle>
        </AccordionSummary>
        <AccordionDetails>
          <PricingPlanFeaturesList>
            <CheckboxItem checked color={"secondary"}>
              Perform ID verification in real-time.
            </CheckboxItem>
            <CheckboxItem checked color={"secondary"}>
              Be sure your customer is who they say they are.
            </CheckboxItem>
            <CheckboxItem checked color={"secondary"}>
              Verify your customer owns a bank account.
            </CheckboxItem>
            <CheckboxItem checked color={"secondary"}>
              Confirm bank data matches other documents.
            </CheckboxItem>
          </PricingPlanFeaturesList>
          <PricingPlanInfo>
            <span>Free</span>
            <InfoCell>
              <span>5 credits per month </span>
              <span>for free</span>
            </InfoCell>
          </PricingPlanInfo>
          <PricingPlanInfo>
            <span>Small</span>
            <InfoCell>
              <span>50 credits</span>
              <span>£1.00/credit</span>
            </InfoCell>
          </PricingPlanInfo>
          <PricingPlanInfo>
            <span>Large</span>
            <InfoCell>
              <span>1,000 credits</span>
              <span>£0.50/credit</span>
            </InfoCell>
          </PricingPlanInfo>
          <PricingPlanInfo>
            <span>Enterprise</span>
            <InfoCell>
              <LetsTalk color={"secondary"} />
            </InfoCell>
          </PricingPlanInfo>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandIcon color={"primary"}/>}
          aria-controls="bankloop-panel"
        >
          <PricingPlanTitle color={"primary"}>
            <span>
              <strong>bank</strong>loop
            </span>
            <span>Verified bank data</span>
          </PricingPlanTitle>
        </AccordionSummary>
        <AccordionDetails>
          <PricingPlanFeaturesList>
            <CheckboxItem checked color={"primary"}>
              Up to 12-months of real-time bank transactions.
            </CheckboxItem>
            <CheckboxItem checked color={"primary"}>
              Analysis identifies sources of regular and irregular
              income.
            </CheckboxItem>
            <CheckboxItem checked color={"primary"}>
              Identify joint accounts.
            </CheckboxItem>
          </PricingPlanFeaturesList>
          <PricingPlanInfo>
            <span>Free</span>
            <InfoCell>
              <span>5 credits per month </span>
              <span>for free</span>
            </InfoCell>
          </PricingPlanInfo>
          <PricingPlanInfo>
            <span>Small</span>
            <InfoCell>
              <span>50 credits</span>
              <span>£2.00/credit</span>
            </InfoCell>
          </PricingPlanInfo>
          <PricingPlanInfo>
            <span>Large</span>
            <InfoCell>
              <span>1,000 credits</span>
              <span>£1/credit</span>
            </InfoCell>
          </PricingPlanInfo>
          <PricingPlanInfo>
            <span>Enterprise</span>
            <InfoCell>
              <LetsTalk color={"primary"} />
            </InfoCell>
          </PricingPlanInfo>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandIcon color={"third"}/>}
          aria-controls="bankloopplus-panel"
        >
          <PricingPlanTitle color={"third"}>
            <span>
              <strong>bank</strong>loop+
            </span>
            <span>Enhanced bank data</span>
          </PricingPlanTitle>
        </AccordionSummary>
        <AccordionDetails>
          <PricingPlanFeaturesList>
            <CheckboxItem checked color={"third"}>
              Analysis of up to 12-months of real-time bank
              transactions.
            </CheckboxItem>
            <CheckboxItem checked color={"third"}>
              Identify sources of regular and irregular income.
            </CheckboxItem>
            <CheckboxItem checked color={"third"}>
              Spending categorisation.
            </CheckboxItem>
            <CheckboxItem checked color={"third"}>
              Summary level insights straight out of the box.
            </CheckboxItem>
            <CheckboxItem checked color={"third"}>
              Identify joint accounts.
            </CheckboxItem>
          </PricingPlanFeaturesList>
          <PricingPlanInfo>
            <span>Free</span>
            <InfoCell>
              <span>5 credits per month </span>
              <span>for free</span>
            </InfoCell>
          </PricingPlanInfo>
          <PricingPlanInfo>
            <span>Small</span>
            <InfoCell>
              <span>50 credits</span>
              <span>£2.00/credit</span>
            </InfoCell>
          </PricingPlanInfo>
          <PricingPlanInfo>
            <span>Large</span>
            <InfoCell>
              <span>1,000 credits</span>
              <span>£1.50/credit</span>
            </InfoCell>
          </PricingPlanInfo>
          <PricingPlanInfo>
            <span>Enterprise</span>
            <InfoCell>
              <LetsTalk color={"third"} />
            </InfoCell>
          </PricingPlanInfo>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default PricingMobile;

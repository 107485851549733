import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LayoutPage from "../../../layout/Page";
import {
  ColCenter,
  Container,
  ContainerNarrow,
  LayoutColSingle,
  Separator,
} from "../../../components/SectionLayout";
import {
  Wrapper,
  Header,
  Content,
  SubContent,
  CardsWrapper,
  Card,
  Title,
} from "components/SectionFeaturesThree";
import {
  Wrapper as CheckOtherWrapper,
  Header as CheckOtherHeader,
  Content as CheckOtherContent,
  Product as CheckOtherProduct,
} from "components/SectionCheckOtherProducts";
import { SvgBgBigCircle } from 'components/svg/BigCircle';
import { SvgBgCircleRed } from 'components/svg/BgCirlceRed';
import useRedirect from "../../../hooks/useRedirect";
import CheckboxItem from "components/Checkbox";

const ProductsBankloopPlusPage = () => {
  const redirectTo = useRedirect();
  const isMobile = useMediaQuery('(max-width: 992px)');

  return (
    <LayoutPage>
      {
        isMobile &&
          <>
            <SvgBgBigCircle top='-17rem' left='-3rem' size='30rem' />
            <SvgBgCircleRed top='6rem' left='95%' size='2rem' />
          </>
      }
      <Separator />
      <Container padding={"0 0 0 0"}>
        <LayoutColSingle>
          <ColCenter>
            <Wrapper name="SectionFeaturesThree">
              <ContainerNarrow>
                <Header color={"third"}>
                  <span>
                    <strong>bank</strong>loop+
                  </span>
                  <span>
                    Deep financial insights with <strong>bank</strong>loop+.
                    Using transactional and behavioural insights, it provides a
                    deep understanding of your customer’s financial behaviour.
                  </span>
                </Header>
              </ContainerNarrow>
              <Content columns={isMobile ? 1 : 2} color={"third"}>
                <SubContent>
                  <span>Product features</span>
                  <span>
                    <ul>
                      <li>
                        <CheckboxItem checked inverted color={"third"}>
                          Analysis of up to 12-months of real-time bank
                          transactions.
                        </CheckboxItem>
                      </li>
                      <li>
                        <CheckboxItem checked inverted color={"third"}>
                          Identify sources of regular and irregular income.
                        </CheckboxItem>
                      </li>
                      <li>
                        <CheckboxItem checked inverted color={"third"}>
                          Spending categorisation.
                        </CheckboxItem>
                      </li>
                      <li>
                        <CheckboxItem checked inverted color={"third"}>
                          Summary level insights straight out of the box.
                        </CheckboxItem>
                      </li>
                      <li>
                        <CheckboxItem checked inverted color={"third"}>
                          Identify joint accounts.
                        </CheckboxItem>
                      </li>
                      <li>
                        <CheckboxItem checked inverted color={"third"}>
                        Affordability calculator support.
                        </CheckboxItem>
                      </li>
                    </ul>
                  </span>
                </SubContent>
                {
                  !isMobile &&
                    <SubContent>
                      <span>Use cases</span>
                      <span>
                        <ul>
                          <li>
                            <CheckboxItem checked inverted color={"third"}>Credit Underwriting</CheckboxItem>
                          </li>
                          <li>
                            <CheckboxItem checked inverted color={"third"}>
                              Portfolio Monitoring
                            </CheckboxItem>
                          </li>
                          <li>
                            <CheckboxItem checked inverted color={"third"}>Collections</CheckboxItem>
                          </li>
                          <li>
                            <CheckboxItem checked inverted color={"third"}>Brokering</CheckboxItem>
                          </li>
                          <li>
                            <CheckboxItem checked inverted color={"third"}>Utility Switching</CheckboxItem>
                          </li>
                          <li>
                            <CheckboxItem checked inverted color={"third"}>Affordability</CheckboxItem>
                          </li>
                          <li>
                            <CheckboxItem checked inverted color={"third"}>Money Management</CheckboxItem>
                          </li>
                        </ul>
                      </span>
                    </SubContent>
                }
              </Content>
            </Wrapper>
          </ColCenter>
        </LayoutColSingle>
      </Container>
      {
        isMobile &&
          <>
            <Separator mobileGap='4rem' />
            <Container padding={'0'}>
              <Title>Use cases</Title>
              <CardsWrapper>
                <Card>Credit Underwriting</Card>
                <Card>Portfolio Monitoring</Card>
                <Card>Collections</Card>
                <Card>Brokering</Card>
                <Card>Utility Switching</Card>
                <Card>Affordability</Card>
                <Card>Money Management</Card>
              </CardsWrapper>
            </Container>
          </>
      }
      <Separator mobileGap='4rem' />
      <Container>
        <LayoutColSingle>
          <ColCenter>
            <CheckOtherWrapper name="SectionCheckOtherProducts">
              <CheckOtherHeader>Check other products</CheckOtherHeader>
              <CheckOtherContent>
                <CheckOtherProduct
                  onClick={redirectTo("/products/bankloop")}
                  color={"primary"}
                >
                  <span>
                    <strong>bank</strong>loop
                  </span>
                  <span>Verified bank data</span>
                </CheckOtherProduct>
                
              </CheckOtherContent>
            </CheckOtherWrapper>
          </ColCenter>
        </LayoutColSingle>
      </Container>
    </LayoutPage>
  );
};

export default ProductsBankloopPlusPage;

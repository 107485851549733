import React, { useEffect } from "react";
import { useHistory } from "react-router";

const useRedirect = () => {
  const [redirect, setRedirect] = React.useState(null);
  const history = useHistory();

  const redirectTo = to => () => setRedirect(to);

  useEffect(() => {
    if (redirect) {
      history.push(redirect);
    }
  }, [redirect, history]);

  return redirectTo;
};

export default useRedirect;

export default {
  timeoutBeforeAutoReject: 60 * 1000 /* 1 min */,
  common: {
    cache: "no-cache",
    referrer: "no-referrer",
    credentials: "include"
  },
  get: {
    method: "GET"
  },
  post: {
    method: "POST"
  },
  del: {
    method: "DELETE"
  }
};

import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LogoWide from "components/svg/LogoWide";
import config from "config/config";
import LinkHref from "components/LinkHref";
import Link from "components/Link";
import LinkArrowDown from "../LinkArrowDown";
import { CSSTransition } from "react-transition-group";
import DropDownSignIn from "./DropDownSignIn";
import DropDownProducts from "./DropDownProducts";
import { useRouteMatch } from "react-router";
import { getColor, getZIndex } from "../../config/theme/selectors";
import { container, containerWide } from "../../config/theme/mixins";
import DecoBarVertical from "../DecoBarVertical";
import clsx from "clsx";

const Wrapper = styled.div`
  ${containerWide};
  position: fixed;
  background-color: ${getColor("bg")};
  z-index: ${getZIndex("overTheTop")};
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  transition: all 200ms;

  &.shadow {
    box-shadow: 10px 0 20px 0 rgba(0, 0, 0, 0.1);
  }
`;

const WrapperInner = styled.div`
  ${container};

  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content 1fr max-content;
  grid-auto-rows: 100%;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 13px;
  width: 100%;
  height: ${({ theme }) => theme.breakpoints.desktop.appBarHeight};
  max-width: ${({ theme }) => theme.breakpoints.desktop.appBarMaxWidth};
  justify-items: center;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  a {
    font-size: 13px;
  }

  @media screen and (max-width: 1281px) {
    padding: 0 10px;
    grid-gap: 1rem;
  }
`;

const LinksRight = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  grid-gap: 3rem;
  align-items: center;
  grid-auto-columns: max-content;
`;

const LinksMiddle = styled.div`
  display: grid;
  grid-auto-flow: column;
  max-width: ${({ theme }) =>
    theme.breakpoints.desktop.appBarLinksMiddleMaxWidth};
  width: 100%;
  padding: 0 10px 0 50px;
  height: 50px;
  justify-content: space-between;
  grid-gap: 2rem;
  align-items: center;
  grid-auto-columns: max-content;
  a {
    font-size: 13px;
  }

  @media screen and (max-width: 1281px) {
    padding: 0 10px 0 20px;
    grid-gap: 1.5rem;
    justify-content: start;
  }
`;

const LinkGetStarted = styled(LinkHref)`
  font-size: 13px;
  line-height: 140%;
  font-weight: 700;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 50px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 2px solid ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const LinkBold = ({ children, ...props }) => (
  <Link {...props} weight="bold">
    {children}
  </Link>
);

const isLinkActive = (match, url) => {
  if (!match) {
    return false;
  }

  if (Array.isArray(url)) {
    return url.includes(match.url);
  }

  return match.url === url;
};

const HeaderPlaceholder = styled.div`
  height: ${({ theme }) => theme.breakpoints.desktop.appBarHeight};
`;

const AppBar = ({ className, onDrawBackdropBg }) => {
  const match = useRouteMatch();
  const isMobile = useMediaQuery('(max-width: 1281px)');
  const dropDownSignInRefSource = useRef(null);
  const [isDropDownSignIn, setDropDownSignIn] = React.useState(false);
  const [classes, setClasses] = useState("");
  const toggleDropDownSignIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropDownSignIn((isVisible) => !isVisible);
  };
  const closeDropDownSignIn = () => setDropDownSignIn(false);

  const dropDownProductsRefSource = useRef(null);
  const [isDropDownProducts, setDropDownProducts] = React.useState(false);
  const toggleDropDownProducts = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropDownProducts((isVisible) => !isVisible);
    onDrawBackdropBg((isDraw) => !isDraw);
  };
  const closeDropDownProducts = () => {
    setDropDownProducts(false);
    onDrawBackdropBg(false);
  };

  const onScroll = useCallback(() => {
    const scroll = window.scrollY;
    if (scroll >= 1) {
      setClasses("shadow");
    } else {
      setClasses("");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <>
      <Wrapper className={clsx(classes, className)}>
        <WrapperInner>
          <LogoWide width={isMobile ? '150' : '196'} />
          <LinksMiddle>
            <LinkBold colorOnHover active={isLinkActive(match, "/")} to="/">
              Home
            </LinkBold>
            <LinkBold
              colorOnHover
              active={isLinkActive(match, "/our_story")}
              to="/our_story"
            >
              Our story
            </LinkBold>
            <LinkArrowDown
              colorOnHover
              ref={dropDownProductsRefSource}
              active={
                !!isDropDownProducts ||
                isLinkActive(match, [
                  "/products/idloop",
                  "/products/bankloop",
                  "/products/bankloopplus",
                ])
              }
              onClick={toggleDropDownProducts}
            >
              Products
            </LinkArrowDown>
            <LinkBold
              colorOnHover
              active={isLinkActive(match, "/pricing")}
              to="/pricing"
            >
              Pricing
            </LinkBold>
            <LinkBold
              colorOnHover
              active={isLinkActive(match, "/faq")}
              to="/faq"
            >
              FAQ
            </LinkBold>
            <LinkBold
              colorOnHover
              active={isLinkActive(match, "/contact_us")}
              to="/contact_us"
            >
              Contact us
            </LinkBold>
            <DecoBarVertical />
            <LinkArrowDown
              ref={dropDownSignInRefSource}
              active={!!isDropDownSignIn}
              onClick={toggleDropDownSignIn}
            >
              Sign in
            </LinkArrowDown>
          </LinksMiddle>
          <LinksRight>
            <LinkGetStarted noHorBar href={config.links.customerSignUp}>
              Get started
            </LinkGetStarted>
          </LinksRight>
        </WrapperInner>
        <CSSTransition
          in={isDropDownSignIn}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <>
            <DropDownSignIn
              refSource={dropDownSignInRefSource}
              close={closeDropDownSignIn}
            />
          </>
        </CSSTransition>
        <CSSTransition
          in={isDropDownProducts}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <>
            <DropDownProducts
              refSource={dropDownProductsRefSource}
              close={closeDropDownProducts}
            />
          </>
        </CSSTransition>
      </Wrapper>
      <HeaderPlaceholder />
    </>
  );
};

export default AppBar;

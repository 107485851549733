import React from "react";
import styled from "styled-components";
import { Link as ReactRouterLink } from "react-router-dom";
import { getColor } from "../../config/theme/selectors";
import { linkHorizontalBarStyled } from "../../config/theme/mixins";

const Wrapper = styled.span`
  a,
  a:visited,
  a:focus {
    color: ${({ active, color }) =>
      active
        ? color
          ? getColor(color)
          : getColor("primary")
        : getColor("fg")};
    text-decoration: none;
    cursor: pointer;
    transition: all 300ms;
  }

  ${linkHorizontalBarStyled};
`;

const Link = ({ to = "", children, ...props }) => (
  <Wrapper {...props}>
    {props.href ? (
      <a href={props.href}>{children}</a>
    ) : (
      <ReactRouterLink to={to}>{children}</ReactRouterLink>
    )}
  </Wrapper>
);

export default Link;

import React from "react";
import { ContainerWide, LayoutColSingle } from "components/SectionLayout";
import {
  Wrapper as FeaturesOneWrapper,
  Content as FeaturesOneContent,
  Header as FeaturesOneHeader,
} from "components/SectionFeaturesOne";
import styled, { css } from "styled-components";
import DecoBarVertical from "../../components/DecoBarVertical";
import { CSSTransition } from "react-transition-group";
import SwitchTransition from "react-transition-group/SwitchTransition";
import SectionFeaturesOneBusiness from "./55-Section-FeaturesOne-Business";
import SectionFeaturesOneIndividuals from "./60-Section-FeaturesOne-Individuals";
import { ElemWithSvgArrow } from "../../components/SectionDialogBubble";
import { Wrapper as CardsOrigin } from "../../components/Cards";

const TabsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  max-width: 500px;
  grid-gap: 5rem;
  grid-auto-rows: 50px;
  align-items: center;

  @media screen and (max-width: 992px) {
    grid-gap: 0;
    margin-bottom: 54px;
    padding: 0 15px;
  }
`;
const Tab = styled(ElemWithSvgArrow)`
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: all 300ms;
  color: ${({ active, theme }) =>
    active ? theme.colors.fg : theme.colors.primary};
  &:hover {
    opacity: 0.8;
  }
  position: relative;
  svg {
    position: absolute;
    display: none;
    left: 50%;
    transform: rotate(90deg) translateY(25%);
    path {
      fill: ${({ theme }) => theme.colorsBranding.grayLight};
    }
    top: 8px;
  }
  ${({ active }) =>
    active &&
    css`
      svg {
        display: block;
      }
    `}

  &:after {
    margin-top: 3rem;
  }

  @media screen and (max-width: 992px) {
    font-size: 13px;
  }
`;

const ContentInner = styled.div`
  max-width: 1240px;
  margin: 0 auto;
`;

export const Cards = styled(CardsOrigin)`
  gap: 3rem 10rem;

  @media screen and (max-width: 1281px) {
    gap: 2rem 6rem;
  }
`;

export const Image = styled.div``;

const SectionFeaturesOne = () => {
  const [tab, setTab] = React.useState("business");

  return (
    <ContainerWide>
      <LayoutColSingle>
        <FeaturesOneWrapper>
          <FeaturesOneHeader>What's the benefit?</FeaturesOneHeader>
          <TabsWrapper>
            <Tab
              active={tab === "business"}
              onClick={() => setTab("business")}
            >
              For Business
            </Tab>
            <DecoBarVertical />
            <Tab
              active={tab === "individuals"}
              onClick={() => setTab("individuals")}
            >
              For Individuals
            </Tab>
          </TabsWrapper>
          <FeaturesOneContent>
            <ContentInner>
              <SwitchTransition>
                <CSSTransition
                  key={tab}
                  addEndListener={(node, done) =>
                    node.addEventListener("transitionend", done, false)
                  }
                  classNames="fade"
                >
                  {tab === "business" ? (
                    <SectionFeaturesOneBusiness />
                  ) : (
                    <SectionFeaturesOneIndividuals />
                  )}
                </CSSTransition>
              </SwitchTransition>
            </ContentInner>
          </FeaturesOneContent>
        </FeaturesOneWrapper>
      </LayoutColSingle>
    </ContainerWide>
  );
};

export default SectionFeaturesOne;

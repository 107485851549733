import styled, { css } from "styled-components";
import { ElemWithSvgArrow } from "../SectionDialogBubble";

export const Header = styled.div`
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  grid-gap: 2rem;
  grid-auto-columns: 100%;

  span:first-child {
    font-size: 45px;
    line-height: 125%;
    color: ${({ color, theme }) =>
      color ? theme.colors[color] : theme.colors.fg};
  }
  span:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 170%;
  }

  @media screen and (max-width: 768px) {
    grid-gap: 1.5rem;

    span:first-child {
      font-size: 31px;
      line-height: 130%;
    }

    span:last-child {
      font-size: 15px;
    }
  }
`;

export const Content = styled(ElemWithSvgArrow)`
  border-radius: 70px;
  padding: 7rem;
  position: relative;
  width: 100%;
  min-height: 30rem;
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${({ gap }) => (gap ? gap : "1rem")};
  justify-content: center;
  justify-items: center;

  @media screen and (max-width: 1281px) {
    padding: 4rem;
    border-radius: 2rem;
  }

  @media screen and (max-width: 992px) {
    min-height: unset;
    padding: 40px;
  }

  svg.bubble-arrow {
    position: absolute;
    transform: rotate(90deg);
    top: -87px;
    fill: ${({ color, theme }) =>
      color ? theme.colors[color] : theme.colors.bgAlt};
  }

  ${({ columns }) =>
    columns === 2 &&
    css`
      grid-gap: 10rem;
      grid-auto-columns: max-content;
      grid-template-columns: 1fr 1fr;
    `};
  background-color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors.bgAlt};
  color: ${({ color, theme }) =>
    color === "secondary" ? theme.colors.fg : theme.colors.bg};
`;

export const SubContent = styled.div`
  display: grid;
  text-align: left;
  grid-auto-flow: row;
  grid-gap: 1rem;
  justify-content: center;
  grid-auto-rows: max-content;

  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  ul {
    list-style: none;
  }

  & > span:first-child {
    font-weight: bold;
    font-size: 30px;
    line-height: 140%;

    @media screen and (max-width: 992px) {
      font-size: 20px;
      text-align: center;
    }
  }
  
  & > span:nth-child(2) {
    font-weight: normal;
    font-size: 18px;
    line-height: 170%;
  }

  ul {
    list-style-position: inside;
    margin: 0;
    padding: 0;
    display: grid;
    grid-auto-flow: row;
    li {
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 100%;
  grid-gap: 5rem;
  width: 100%;
  justify-content: center;
  justify-items: center;
  height: max-content;
  align-items: center;
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  grid-gap: 10px;
  margin: 30px 30px 0;
`;

export const Card = styled.div`
  width: 100%;
  max-width: 315px;
  padding: 0.8rem 2.5rem;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  font-size: 14px;
`;

export const Title = styled.span`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 125%;
`;

export default {
  Wrapper,
  Content,
  Header,
  CardsWrapper,
  Card,
  Title
};

import styled from "styled-components";
import { absAfter } from "../../config/theme/mixins";

export const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 140%;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width: 100%;
  grid-auto-rows: 200px;

  border-top: 1px solid ${({ theme }) => theme.colors.fgLite};

  & > div:first-child {
    border-right: 1px solid ${({ theme }) => theme.colors.fgLite};
  }

  @media screen and (max-width: 768px) {
    grid-auto-flow: row;
    grid-auto-rows: 115px;
    width: auto;
    margin: 0 -40px;

    & > div:first-child {
      border-right: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.fgLite};
    }
  }
`;

export const Product = styled.div`
  cursor: pointer;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  width: 100%;
  grid-auto-columns: 100%;
  grid-auto-row: 1fr;
  justify-items: center;
  position: relative;

  &::after {
    transition: all 300ms;
    ${absAfter};
    opacity: 0;
    width: 50%;
    height: 60%;
    border-radius: 8px;
    transform: translate(50%, 40%);
    box-shadow: 0 50px 50px 5px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  & > span:first-child {
    transition: all 300ms;
    display: flex;
    align-items: flex-end;
    font-style: normal;
    font-size: 35px;
    line-height: 150%;
    color: ${({ color, theme }) =>
      color ? theme.colors[color] : theme.colors.fg};
    strong {
      line-height: 150%;
    }
  }
  & > span:last-child {
    display: flex;
    align-items: flex-start;
    font-weight: normal;
    font-size: 15px;
    line-height: 170%;
    color: ${({ theme }) => theme.colors.fgLight};
  }

  @media screen and (max-width: 768px) {
    grid-gap: .5rem;
    padding: 0 40px;
    justify-items: start;

    &::after {
      display: none;
    }

    & > span:first-child {
      line-height: 100%;

      strong {
        line-height: 100%;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 3rem;
  width: 100%;
  grid-auto-columns: 100%;
  height: max-content;
  align-items: center;

  @media screen and (max-width: 768px) {
    grid-gap: 1.5rem;
  }
`;

export default {
  Wrapper,
  Content,
  Header,
};

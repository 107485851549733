import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from 'components/AppBar';
import AppBarMobile from 'components/AppBarMobile';
import Footer from 'components/Footer';
import * as mixins from 'config/theme/mixins';
import { absAfter } from 'config/theme/mixins';
import useScrollToTop from '../hooks/useScrollToTop';
import { CSSTransition } from 'react-transition-group';

const bgImage = css`
  position: relative;
  width: 100%;
  &:after {
    ${absAfter};
    opacity: 0.5;
    max-width: ${({ theme }) => theme.breakpoints.desktop.containerMaxWidth};
    height: 100%;
    z-index: -1;
  }
`;

const WrapperStyled = styled.div`
  display: grid;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  justify-items: center;
  height: 100%;
  grid-template: ${({ theme }) => theme.breakpoints.desktop.appBarHeight} 1fr min-content / 100%;
  &::after {
    pointer-events: none;
    ${mixins.absAfter};
    background-size: cover;
    z-index: ${({ theme }) => theme.zIndex.top};
  }
  ${bgImage};
`;

const PageContent = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  grid-auto-flow: row;
  grid-auto-columns: 100%;
  height: max-content;
  width: 100%;
`;

const Background = styled.div`
  background-color: #00000055;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
`;

const LayoutPage = ({ children }) => {
  useScrollToTop();
  const isDesktop = useMediaQuery('(min-width:992px)');

  const [drawBackdropBg, setDrawBackdropBg] = useState(false);

  return (
    <WrapperStyled>
      { isDesktop ? 
        <AppBar onDrawBackdropBg={setDrawBackdropBg} />
        : 
        <AppBarMobile />
      }
      <>
        <PageContent>{children}</PageContent>
        <CSSTransition
          in={drawBackdropBg}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <Background />
        </CSSTransition>
      </>
      <Footer />
    </WrapperStyled>
  );
};

export default LayoutPage;

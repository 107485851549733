import React from 'react';
import styled from 'styled-components';
import LinkHref from "../../components/LinkHref";
import {
  LayoutColSingle,
  Container,
} from "components/SectionLayout";
import {
  Content,
  Header,
  Wrapper,
} from "components/SectionFaq";
import {
  Card as CardOrigin,
  Content as CardsContentOrigin,
  Header as CardHeaderOrigin,
  Wrapper as CardsOrigin,
} from "components/Cards";

const CardsContent = styled(CardsContentOrigin)`
  color: ${({ theme }) => theme.colorsBranding.grayDark};

  p {
    line-height: 170%;
  }
`;

const Cards = styled(CardsOrigin)`
  gap: 5rem;

  @media screen and (max-width: 1281px) {
    gap: 3rem;
  }

  @media screen and (max-width: 768px) {
    gap: 1rem;
  }
`;

const CardHeader = styled(CardHeaderOrigin)`
  font-size: 20px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const Card = styled(CardOrigin)`
  grid-template-rows: max-content max-content;
  grid-template-columns: unset;
  grid-gap: 0.5rem 0;
`;

const FaqContent = () => {
  return (
    <Container padding={"0 0 0 0"}>
      <LayoutColSingle>
        <Wrapper>
          <Header>Frequently asked questions</Header>
          <Content>
            <Cards>
              <Card>
                <CardHeader>Is TrustLoop regulated?</CardHeader>
                <CardsContent>
                  <p>
                    Yes. TrustLoop is a trading name of Open B Gateway
                    Limited, regulated by the Financial Conduct Authority as
                    an Account Information Service Provider which allows us to
                    seek your consent for read-only access to your Open
                    Banking data.
                  </p>
                  <p>
                    We can be found on the FCA Register{" "}
                    <LinkHref
                      href={
                        "https://register.fca.org.uk/s/firm?id=0010X00004bm6bnQAA"
                      }
                      underline
                      color={"primary"}
                    >
                      here.
                    </LinkHref>
                  </p>
                </CardsContent>
              </Card>
              <Card>
                <CardHeader>What is Open Banking?</CardHeader>
                <CardsContent>
                  <p>
                    Open Banking can seem confusing but{" "}
                    <LinkHref
                      href={"https://www.youtube.com/watch?v=vVKGnSoQtGQ"}
                      color={"primary"}
                      underline
                    >
                      this short video
                    </LinkHref>{" "}
                    from the UK’s Open Banking Implementation Entity explains
                    the basics.
                  </p>
                </CardsContent>
              </Card>
              <Card>
                <CardHeader>What data will I be sharing?</CardHeader>
                <CardsContent>
                  TrustLoop only asks you to share the bare minimum data
                  required for our customer to deliver their service to you.
                  This tends to be your most recent 12-months of bank
                  transactions but our consent process clearly states the
                  exact scope of data that you will be sharing, and your bank
                  is not allowed to share any data with TrustLoop outside of
                  that scope.
                </CardsContent>
              </Card>
              <Card>
                <CardHeader>
                  Will I need to share my online banking login details?
                </CardHeader>
                <CardsContent>
                  No. This is the backbone of Open Banking and what makes it
                  so safe and secure. You enter your online banking login
                  details to your bank’s website - by doing this, your bank
                  recognises that you consent to share your Open Banking data
                  with TrustLoop. This part of the process is purely between
                  you and your bank.
                </CardsContent>
              </Card>
              <Card>
                <CardHeader>Who will my data be shared with?</CardHeader>
                <CardsContent>
                  TrustLoop brings together four parties: our customer, you
                  and your bank, and TrustLoop itself. By consenting to use
                  TrustLoop you approve of all four parties having access to
                  your Open Banking data. You may revoke consent at any time
                  via your account.
                </CardsContent>
              </Card>
              <Card>
                <CardHeader>
                  How long will TrustLoop have access to my data?
                </CardHeader>
                <CardsContent>
                  The standard duration of a consent request is 90 days,
                  during which TrustLoop may fetch your Open Banking data and
                  share it with our customer. You may revoke consent at any
                  time via your account.
                </CardsContent>
              </Card>
              <Card>
                <CardHeader>Will my data be held securely?</CardHeader>
                <CardsContent>
                  <p>
                    The Open Banking standards use cutting edge technology to
                    strictly specify the security framework used to transport
                    your data from your bank to TrustLoop.
                  </p>
                  <p>
                    TrustLoop encrypts your data at rest and in transit. To
                    decrypt the data a special “key” is required - this key is
                    held securely by TrustLoop and is rotated regularly for
                    even greater security.
                  </p>{" "}
                  <p>
                    Furthermore, TrustLoop has strict Terms of Use and Privacy
                    Policies in place to ensure that our customers are
                    treating your data with the respect it deserves.
                  </p>
                </CardsContent>
              </Card>
            </Cards>
          </Content>
        </Wrapper>
      </LayoutColSingle>
    </Container>
  );
}

export default FaqContent;

import styled from "styled-components";

const HeaderWithSubHeader = styled.div`
  display: grid;
  height: max-content;
  grid-auto-flow: row;
  grid-gap: 1rem;

  & > span:first-child {
    font-weight: bold;
    font-size: 55px;
    line-height: 125%;

    @media screen and (max-width: 992px) {
      font-size: 31px;
    }
  }

  & > span:last-child {
    font-weight: normal;
    font-size: 18px;
    line-height: 170%;
  }
`;

export default HeaderWithSubHeader;

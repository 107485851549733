import styled from "styled-components";
import * as mixins from "config/theme/mixins";

const DecoBarVertical = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  &::after {
    ${mixins.absAfterCenterX};
    width: 1px;
    background-color: ${({ theme }) => theme.colors.fgLite};
    height: 100%;
  }
`;

export default DecoBarVertical;

import React from "react";
import styled, { css } from "styled-components";
import { svg } from "../../config/theme/mixins";

const Wrapper = styled.div`
  ${svg};
  width: 30px;
  height: 30px;
  border-radius: 50%;

  svg {
    position: relative;
    top: 2px;

    .Mui-expanded & {
      top: 0;
    }
  }

  ${({ color, theme }) => {
    if (color) {
      return css`
        background-color: ${theme.colors[color]};
      `;
    }
  }};
`;

const ExpandIcon = ({ active, color }) => {
  return (
    <Wrapper active={active} color={color}>
      <svg 
        width="11" 
        height="7" 
        viewBox="0 0 11 7" 
        fill="none"
        xmlns="http://www.w3.org/2000/svg" 
      >
        <path 
          d="M1 1L5.24264 4.84804L9.48528 1" 
          stroke="white" 
          strokeWidth="2" 
          strokeLinecap="round"
        />
      </svg>
    </Wrapper>
  );
};

export default ExpandIcon;

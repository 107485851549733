import React, { useState } from 'react';
import styled from 'styled-components';
import LogoWide from 'components/svg/LogoWide';
import { getZIndex } from "../../config/theme/selectors";
import { containerWide } from '../../config/theme/mixins';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from './Menu';
import { CSSTransition } from 'react-transition-group';

const Wrapper = styled.div`
  ${containerWide}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
`;

const MenuIconWrapper = styled.span`
  display: block;
  position: fixed;
  bottom: 30px;
  right: 30px;
  color: ${({ theme }) => theme.colors.primary};
  z-index: ${getZIndex("overTheTop")};
  
  .MuiFab-root {
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #ffffff;
    }
  }
`;

const AppBarMobile = () => {
  const [open, setOpen] = useState(false);
  const [submenu, setSubmenu] = useState('');

  const toggleOpen = () => {
    setOpen((open) => !open)
    setSubmenu('')
  };
  
  return (
    <Wrapper>
      <LogoWide width='155px' />
      <CSSTransition
        in={open}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <Menu
          open={open}
          submenu={submenu}
          toggleOpen={toggleOpen}
          setSubmenu={setSubmenu}
        />
      </CSSTransition>
      
      <MenuIconWrapper>
        <Fab color={'inherit'} onClick={() => toggleOpen()}>
          {!open ? <MenuIcon /> : <CloseIcon />}
        </Fab>
      </MenuIconWrapper>
    </Wrapper>
  )
}

export default AppBarMobile

import React from 'react';
import styled from 'styled-components';
import { getColor } from "../../config/theme/selectors";
import useRedirect from "../../hooks/useRedirect";

const ProductLink = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: row;
  justify-items: start;
  margin-bottom: 1rem;
  font-size: 30px;
  line-height: 140%;
  font-weight: normal;
  color: ${({ color, theme }) => color ? theme.colors[color] : theme.colors.primary};
  cursor: pointer;
  transition: all 300ms;

  span:first-child {
    font-size: 35px;
    line-height: 175%;
  }

  span:last-child {
    font-size: 15px;
    line-height: 100%;
    color: ${getColor("fgLight")};
  }
`;

const ProductsSubmenu = () => {
  const redirectTo = useRedirect();

  return (
    <div>
      <ProductLink color={"secondary"} onClick={redirectTo("/products/idloop")}>
        <span>
          <strong>id</strong>
          loop
        </span>
        <span>ID verification</span>
      </ProductLink>
      <ProductLink color={"primary"} onClick={redirectTo("/products/bankloop")}>
        <span>
          <strong>back</strong>
          loop
        </span>
        <span>Verified bank data</span>
      </ProductLink>
      <ProductLink color={"third"} onClick={redirectTo("/products/bankloopplus")}>
        <span>
          <strong>bank</strong>
          loop+
        </span>
        <span>Enhanced bank data</span>
      </ProductLink>
    </div>
  );
}

export default ProductsSubmenu;

import config from "./fetch-config";
import { toast } from "react-toastify";
import history from "./history";

export const apiCallWrapper = async ({
  apiCall,
  onFail = null,
  onFinish = null,
  onStart = () => {},
  onAny = () => {},
  notify = false,
  redirectTo = null,
  areSessionExpiredRedirect = true,
  addMsg = null,
}) => {
  onStart();
  try {
    const resp = await apiCall();
    onAny();
    if (onFinish) {
      return onFinish(resp);
    }
    return resp;
  } catch (error) {
    onAny();
    return fetchErrorsHandler({
      error,
      notify,
      redirectTo,
      areSessionExpiredRedirect,
      addMsg,
      customErrorHandler: onFail,
    });
  }
};

const doNotify = (isNeedNotify, errMsg) => {
  if (isNeedNotify) {
    toast.error(errMsg);
  }
};

export const fetchErrorsHandler = ({
  error,
  redirectTo,
  areSessionExpiredRedirect,
  notify: optNotify,
  customErrorHandler,
  addMsg,
}) => {
  console.debug("fetchErrorHandler. Row error from JS: ", error);
  let notify = optNotify;
  let errMsg = parseResponseStatusMessage(error);

  switch (error.code) {
    case 401:
    case 400:
      notify = true;
      break;
    case 500:
      notify = true;
      if (!errMsg) {
        errMsg = "Network connection error";
      }
      break;
    case 440:
      errMsg = "Session expired";
      if (areSessionExpiredRedirect) {
        history.push("/log_out");
      }
      break;
    default:
      if (!errMsg) {
        errMsg = "Something went wrong. Please, try again later";
      }
      break;
  }

  console.debug({ errMsg, code: error.code, error, addMsg });

  doNotify(notify, errMsg);

  if (redirectTo) {
    history.push(redirectTo);
  }

  let ret = null;

  if (customErrorHandler) {
    ret = customErrorHandler(errMsg);
  }

  return ret || new Error(errMsg);
};

export const parseResponseStatusMessage = ({ code, text }) =>
  (code && text && `${text}`) || null;

export const http = async (url, options) =>
  new Promise((resolve, reject) => {
    let response;
    fetch(url, options)
      .then((resp) => {
        response = resp;
        return resp
          .json()
          .then((r) => r)
          .catch((e) => {
            if (response.ok) {
              return null;
            }
            return e;
          });
      })
      .then((resp) => {
        if (response.ok) {
          resolve((resp && resp.body) || resp || null);
          return;
        }

        reject((resp && resp.status) || resp || response);
      })
      .catch((error) => {
        reject(error);
      });
  });

const httpTimeout = async (
  url,
  options,
  timeout = config.timeoutBeforeAutoReject
) =>
  Promise.race([
    http(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject("connection_timeout"), timeout)
    ),
  ]);

export const post = async (url, body, timeout) =>
  httpTimeout(
    url,
    {
      ...config.common,
      ...config.post,
      body: JSON.stringify(body),
    },
    timeout
  );

export const get = async (url, timeout) =>
  httpTimeout(
    url,
    {
      ...config.common,
      ...config.get,
    },
    timeout
  );

export const del = async (url, timeout) => {
  return httpTimeout(
    url,
    {
      ...config.common,
      ...config.del,
    },
    timeout
  );
};

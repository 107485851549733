import styled from "styled-components";
import { ElemWithSvgArrow } from "../SectionDialogBubble";

export const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 140%;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Content = styled(ElemWithSvgArrow)`
  background: ${({ theme }) => theme.colors.bgAlt};
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  padding: 7rem;
  position: relative;

  svg {
    position: absolute;
    transform: rotate(90deg) translateY(28px);
    top: -86px;
    left: 50%;
    fill: ${({ theme }) => theme.colors.bgAlt};
  }

  @media screen and (max-width: 1281px) {
    padding: 4rem;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 40px 75px;
    border-radius: 0;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 5rem;
  height: max-content;
  align-items: center;

  @media screen and (max-width: 768px) {
    grid-gap: 3.5rem;
  }
`;

export default {
  Wrapper,
  Content,
  Header,
};

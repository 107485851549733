import React from 'react';
import {
  ColCenter,
  LayoutColSingle,
  Container,
} from "components/SectionLayout";
import styled, { css } from "styled-components";
import { getColor, getColorByProps } from "../../config/theme/selectors";
import ArrowRight from "../../components/svg/ArrowRight";
import { absAfter, linkHorizontalBar } from "../../config/theme/mixins";
import LinkHref from "../../components/LinkHref";
import Checkbox from '../../components/Checkbox';

const mixinBorderBottomPseudoAfter = css`
  pointer-events: none;
  ${absAfter};
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 6rem);
  border-bottom: 1px solid ${getColor("fgLite")};
`;

const mixinBorderBottom = css`
  position: relative;
  &:after {
    ${mixinBorderBottomPseudoAfter};
  }
`;

const mixinBorderBottomFirst = css`
  position: relative;
  &:after {
    ${mixinBorderBottomPseudoAfter};
    transform: translateX(-5rem);
    width: calc(100% + 4rem);
  }
`;

const mixinBorderBottomLast = css`
  position: relative;
  &:after {
    ${mixinBorderBottomPseudoAfter};
    transform: translateX(0);
    left: -4rem;
    width: calc(100% + 0);
  }
`;

const PricingWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2rem;
  & > div:last-child {
    & > span {
      ${mixinBorderBottomLast}
    }
  }

  @media screen and (max-width: 1281px) {
    grid-gap: 1rem;
  }
`;
const PricingHeaders = styled.div`
  display: grid;
  grid-template: 400px repeat(4, 100px) / 180px;
  padding: 2rem 2rem;

  @media screen and (max-width: 1281px) {
    display: none;
  }

  & > span {
    ${mixinBorderBottomFirst};
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    display: flex;
  }

  & > span:last-child {
    &::after {
      display: none;
    }
  }

  & > span:not(:first-child) {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 130%;
    text-transform: uppercase;
  }

  & > span:first-child {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem;
    grid-auto-rows: max-content;
    justify-items: start;
    text-align: left;
    align-content: center;

    & > span:first-child {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 140%;
    }

    & > span:nth-child(2) {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
    }
    & > span:nth-child(3) {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
const PricingPlan = styled.div`
  display: grid;
  grid-template: 400px repeat(4, 100px) / 240px;
  justify-items: center;
  align-content: flex-start;
  width: 100%;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 2rem 2rem;
  & > span {
    align-content: center;
    height: 100%;
    align-items: center;

    ${mixinBorderBottom}
  }

  & > span:last-child {
    &::after {
      display: none;
    }
  }

  & > span:first-child {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 3rem;
    grid-auto-rows: max-content;
    justify-items: baseline;
    width: 100%;
    align-content: flex-start;
  }
  & > span:not(:first-child) {
    width: 100%;
  }
`;
const PricingPlanTitle = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0;
  grid-auto-rows: max-content;
  width: 100%;
  height: 100%;
  grid-auto-columns: 100%;
  justify-items: flex-start;

  & > span:first-child {
    font-style: normal;
    font-size: 35px;
    line-height: 150%;
    color: ${getColorByProps()};
  }

  & > span:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 170%;
    color: ${getColor("fgLight")};
  }
`;
const PricingPlanFeaturesList = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  justify-content: center;
  width: 100%;
  grid-auto-columns: 100%;
  word-break: break-all;
  overflow: hidden;
  padding-bottom: 1.5rem;
`;

const CheckboxItemSmall = styled(Checkbox)`
  & > span:nth-child(2) {
    font-size: 12px;
    font-weight: 500;
  }
`;

const InfoCellWrapper = styled.div`
  display: grid;
  grid-template:
    "title arrowRight" max-content
    "subTitle arrowRight" max-content / 1fr max-content;
  grid-gap: 0.25rem 0;
  align-items: center;
  width: 100%;
  height: 100%;
  align-content: center;

  & > span:first-child {
    transition: all 300ms;
    grid-area: title;
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 170%;
  }
  & > span:nth-child(2) {
    transition: all 300ms;
    display: flex;
    grid-area: subTitle;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color: ${getColor("fgLight")};
  }
  & > span:nth-child(3) {
    transition: all 300ms;
    grid-area: arrowRight;
  }
`;
const InfoCell = ({ color, children }) => (
  <InfoCellWrapper color={color}>
    {React.Children.toArray(children)}
  </InfoCellWrapper>
);
const LetsTalkWrapper = styled.div`
  display: grid;
  grid-template: "title arrowRight" max-content / 1fr max-content;
  grid-gap: 0.25rem 0;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  height: 100%;
  align-content: center;
  cursor: pointer;

  & > span:first-child {
    position: relative;
    grid-area: title;
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 170%;
    color: ${getColorByProps()};

    ${linkHorizontalBar()};
  }
  & > span:nth-child(2) {
    grid-area: arrowRight;
  }

  & > span:nth-child(1),
  & > span:nth-child(2) {
    transition: all 300ms;
  }
`;
const LetsTalk = ({ color }) => (
  <LinkHref href={"https://trustloop.io/contact_us"}>
    <LetsTalkWrapper color={color}>
      <span>Let's talk</span>
      <span>
        <ArrowRight color={color} />
      </span>
    </LetsTalkWrapper>
  </LinkHref>
);

const PricingDesktop = () => {
  return (
    <Container>
        <LayoutColSingle>
          <ColCenter>
            <PricingWrapper>
              <PricingHeaders>
                <span>
                  <span>Products and packages</span>
                  <span>
                    Choose product and package of credits that fits your needs
                  </span>
                  <span>What is credits?</span>
                </span>
                <span>FREE</span>
                <span>Check via Portal</span>
                <span>Check via API</span>
                <span>ENTERPRISE</span>
              </PricingHeaders>
              <PricingPlan>
                <span>
                  <PricingPlanTitle color={"primary"}>
                    <span>
                      <strong>bank</strong>loop
                    </span>
                    <span>Verified bank data</span>
                  </PricingPlanTitle>
                  <PricingPlanFeaturesList>
                    <CheckboxItemSmall checked color={"primary"}>
                      Up to 12-months of real-time bank transactions.
                    </CheckboxItemSmall>
                    <CheckboxItemSmall checked color={"primary"}>
                      Analysis identifies sources of regular and irregular
                      income.
                    </CheckboxItemSmall>
                    <CheckboxItemSmall checked color={"primary"}>
                      Identify joint accounts.
                    </CheckboxItemSmall>
                  </PricingPlanFeaturesList>
                  {/*<PricingPlanFeaturesList>
                    <CheckboxItem checked color={"primary"}>
                      Feature 1
                    </CheckboxItem>
                    <CheckboxItem checked color={"primary"}>
                      Some Feature 2
                    </CheckboxItem>
                    <CheckboxItem>Other Feature 3</CheckboxItem>
                  </PricingPlanFeaturesList>*/}
                </span>
                <span>
                  <InfoCell>
                    <span>5 credits per month</span>
                    <span>for free</span>
                  </InfoCell>
                </span>
                <span>
                  <InfoCell>
                    <span>15.00p/check</span>
                  </InfoCell>
                </span>
                <span>
                  <InfoCell>
                    <span>20.00p/check</span>
                  </InfoCell>
                </span>
                <span>
                  <LetsTalk color={"primary"} />
                </span>
              </PricingPlan>
              <PricingPlan>
                <span>
                  <PricingPlanTitle color={"third"}>
                    <span>
                      <strong>bank</strong>loop+
                    </span>
                    <span>Enhanced bank data</span>
                  </PricingPlanTitle>
                  <PricingPlanFeaturesList>
                    <CheckboxItemSmall checked color={"third"}>
                      Analysis of up to 12-months of real-time bank
                      transactions.
                    </CheckboxItemSmall>
                    <CheckboxItemSmall checked color={"third"}>
                      Identify sources of regular and irregular income.
                    </CheckboxItemSmall>
                    <CheckboxItemSmall checked color={"third"}>
                      Spending categorisation.
                    </CheckboxItemSmall>
                    <CheckboxItemSmall checked color={"third"}>
                      Summary level insights straight out of the box.
                    </CheckboxItemSmall>
                    <CheckboxItemSmall checked color={"third"}>
                      Identify joint accounts.
                    </CheckboxItemSmall>
                    <CheckboxItemSmall checked color={"third"}>
                      Affordability calculator support.
                    </CheckboxItemSmall>
                  </PricingPlanFeaturesList>
                  {/*<PricingPlanFeaturesList>
                    <CheckboxItem checked color={"third"}>
                      Analysis of up to 12-months of real-time bank
                      transactions.
                    </CheckboxItem>
                    <CheckboxItem checked color={"third"}>
                      Identify sources of regular and irregular income.
                    </CheckboxItem>
                    <CheckboxItem checked color={"third"}>
                      Spending categorisation.
                    </CheckboxItem>
                    <CheckboxItem checked color={"third"}>
                      Summary level insights straight out of the box.
                    </CheckboxItem>
                    <CheckboxItem checked color={"third"}>
                      Identify joint accounts.
                    </CheckboxItem>
                  </PricingPlanFeaturesList>*/}
                </span>
                <span>
                  <InfoCell>
                    <span>5 credits per month </span>
                    <span>for free</span>
                  </InfoCell>
                </span>
                <span>
                  <InfoCell>
                    <span>25.00p/check</span>
                  </InfoCell>
                </span>
                <span>
                  <InfoCell>
                    <span>35.00p/check</span>
                  </InfoCell>
                </span>
                <span>
                  <LetsTalk color={"third"} />
                </span>
              </PricingPlan>
            </PricingWrapper>
          </ColCenter>
        </LayoutColSingle>
      </Container>
  );
}

export default PricingDesktop;

import React, { useState, useEffect } from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Container, LayoutColSingle } from "components/SectionLayout";
import styled from "styled-components";
import ArrowDown from 'components/svg/ArrowDown';

const Wrapper = styled.div`
  padding: 1rem 0;

  @media screen and (max-width: 992px) {
    padding: 0;
  }
`;
const Item = styled.div`
  padding: 1rem 2.5rem;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 315px;
    padding: 0.8rem 2.5rem;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  gap: 2rem 2rem;
  grid-template-columns: max-content max-content max-content max-content max-content;
  grid-template-rows: max-content max-content;
  margin-top: 3rem;
  
  @media screen and (max-width: 1281px) {
    justify-content: center;
    grid-template-columns: max-content max-content max-content;
    grid-template-rows: auto;
    margin: 3rem auto;
  }

  @media screen and (max-width: 992px) {
    grid-template-columns: max-content max-content;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    place-items: center;
    grid-gap: 10px;
    margin: 30px -10px 0;
  }
`;

const Title = styled.span`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 125%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 992px) {
    font-size: 20px;
  }
`;

const ExpandLink = styled.span`
  display: flex;
  align-items: center;
  padding: 20px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.05px;

  div {
    margin-left: 5px;
    margin-top: -5px;
  }

  svg path {
    stroke: ${({ theme }) => theme.colors.primary};
  }
`;

const useCasesList = [
  'Tenant Vetting',
  'Credit Underwriting',
  'Portfolio Monitoring',
  'Debt Collection',
  'Brokering',
  'Know Your Customer',
  'Vulnerability Checks',
  'Utility Switching',
  'Affordability Assessments',
  'Money Management',
]

const SectionUseCasesNew = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [expanded, setExpanded] = useState();

  useEffect(() => {
    setExpanded(!isMobile)
  }, [isMobile])

  const numberOfItems = expanded ? 10 : 5

  return (
    <Container>
      <LayoutColSingle>
        <Wrapper>
          <Title>Use cases</Title>
          <ContentWrapper>
            {
              useCasesList.slice(0, numberOfItems).map(item => (
                <Item key={item}>{item}</Item>
              ))
            }
            {
              !expanded &&
              <ExpandLink onClick={() => setExpanded(true)}>View more use cases <ArrowDown /></ExpandLink>
            }
          </ContentWrapper>
        </Wrapper>
      </LayoutColSingle>
    </Container>
  );
};

export default SectionUseCasesNew;

import React from "react";
import { Container, LayoutColTwo } from "components/SectionLayout";
import {
  Wrapper as BubbleWrapper,
  SideContent,
  Content,
} from "components/SectionDialogBubble";

const SectionBubbleWhyTakeAdvantage = () => {
  return (
    <Container padding={"0 0 0 0"}>
      <LayoutColTwo mobileGap='3rem'>
        <SideContent>
          <h3>Why take advantage of Open Banking?</h3>
        </SideContent>
        <BubbleWrapper>
          <Content>
            <p>
              The open banking initiative has revolutionised consumer credit in
              the UK, both for lenders and for borrowers.
            </p>
            <p>
              The <strong>wider wealth of transactional data</strong> made
              available through open banking enables deep, real-time analysis of
              customers' spending habits, sources of income, credit histories
              and more.
            </p>
            <p>
              This gives lenders a truly clear and accurate view of each
              consumer's <strong>creditworthiness</strong> - resulting in safer
              <strong>lending decisions</strong> than ever before, and giving
              <strong>consumers greater control</strong> over their personal
              finances.
            </p>
          </Content>
        </BubbleWrapper>
      </LayoutColTwo>
    </Container>
  );
};

export default SectionBubbleWhyTakeAdvantage;
